import React from "react";
import imgMaosEDedos1 from "../../../assets/produtos_proteses_maos_dedos_1.jpg"
import imgMaosEDedos2 from "../../../assets/produtos_proteses_maos_dedos_2.jpg"
import imgMaosEDedos3 from "../../../assets/produtos_proteses_maos_dedos_3.jpg"
import imgMaosEDedos4 from "../../../assets/produtos_proteses_maos_dedos_4.jpg"
import imgMaosEDedos5 from "../../../assets/produtos_proteses_maos_dedos_5.jpg"
import imgMaosEDedos6 from "../../../assets/produtos_proteses_maos_dedos_6.jpg"
import imgMaosEDedos9 from "../../../assets/produtos_proteses_maos_dedos_9.jpg"
import imgMaosEDedos10 from "../../../assets/produtos_proteses_maos_dedos_10.jpg"
import imgMaosEDedos11 from "../../../assets/produtos_proteses_maos_dedos_11.jpg"
import imgMaosEDedos12 from "../../../assets/produtos_proteses_maos_dedos_12.jpg"

function MaosEDedos() {
    return (
        <>
            <h2>Amputação de mãos e dedos</h2>
            <h3>Próteses funcionais para amputações parciais da mão</h3>
            <p>
                As amputações na região da mão podem ser protetisadas com componentes estéticos ou funcionais.<br />
                Uma possibilidade é a fixação de uma tala pouco acima do punho, o que permite a preensão de objetos, além de manter a sensibilidade.<br />
                A utilização de uma prótese funcional sempre proporciona um melhor resultado reabilitatório.<br />
                Na prótese apresentada na figura, o polegar e os dedos estão unidos através de uma articulação.<br />
                A abertura e o fechamento dão-se através da flexão e extensão do punho.<br />
                Este tipo de prótese ativa permite uma adaptação muito rápida. Porém, a estética fica bastante comprometida.
            </p>
            <div className="images">
                <img src={imgMaosEDedos1} alt="Mãos e dedos" className="image"/>
                <img src={imgMaosEDedos2} alt="Mãos e dedos" className="image"/>
                <img src={imgMaosEDedos3} alt="Mãos e dedos" className="image"/>
                <img src={imgMaosEDedos4} alt="Mãos e dedos" className="image"/>
            </div>
            <h3>Próteses funcionais Biônicas, para amputações parciais da mão</h3>
            <p>
                As próteses funcionais Biônicas, para amputações parciais das mãos são  customizadas para cada caso e, através de pulsos mioelétricos obtidos na musculatura remanescente, consegue-se movimentos naturais e funcionais dos dedos (cada caso tem que ser avaliado para verificação da viabilidade uso desse tipo de prótese.
            </p>
            <div className="images">
                <img src={imgMaosEDedos5} alt="Mãos e dedos" className="image"/>
            </div>
            <h3>Próteses estéticas para amputações de dedos das mãos</h3>
            <p>
                As Próteses Estéticas, geralmente, são feitas de silicone ou de látex, e tem finalidade puramente estética, ou seja, não tem qualquer função, de preensão, pinça, etc. simplesmente buscam complementar a parte amputada, dando-lhe a aparência mais parecida possível do natural.
            </p>
            <div className="images">
                <img src={imgMaosEDedos6} alt="Mãos e dedos" className="image"/>
                <img src={imgMaosEDedos9} alt="Mãos e dedos" className="image"/>
                <img src={imgMaosEDedos10} alt="Mãos e dedos" className="image"/>
                <img src={imgMaosEDedos11} alt="Mãos e dedos" className="image"/>
                <img src={imgMaosEDedos12} alt="Mãos e dedos" className="image"/>
            </div>
        </>
    )
}

export default MaosEDedos;