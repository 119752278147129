import React from "react";
import imgQuadril1 from "../../../assets/produtos_orteses_quadril_1.jpg"
import imgQuadril2 from "../../../assets/produtos_orteses_quadril_2.jpg"
import imgQuadril3 from "../../../assets/produtos_orteses_quadril_3.jpg"

function Quadril() {
    return (
        <>
            <h2>Quadril</h2>
            <p>
                Apresentamos aqui, apenas alguns modelos mais comuns. Trabalhamos com todos os modelos de órteses ortopédicas.
            </p>
            <h3>Abdução de quadril, de quadril Atlanta Brace ou Schotish Brite</h3>
            <p>
                Indicações:<br />
                Tratamento de Legg Perthes e Luxação Congênita de Quadril
            </p>
            <p>
                Características:<br />
                Possibilita ampla libertade de movimentos mantendo abdução de 45° em ambas articulações do quadril;<br />
                Com haste telescópica e juntas esféricas universais de alta qualidade;<br />
                Peças de coxa e cinto pélvico moldados em polipropileno, com nervuras de reforço estrutural;<br />
                provida de regulagem variável para acompanhar o crescimento do paciente;<br />
                Peças revestidas internamente com espuma softform para maior conforto;<br />
                Estrutura metálica com revestimento anti-oxidante;<br />
                Fechos de velcro.
            </p>
            <div className="images">
                <img className="image" src={imgQuadril1} alt="Quadril"/>
                <img className="image" src={imgQuadril2} alt="Quadril"/>
                <img className="image" src={imgQuadril3} alt="Quadril"/>
            </div>
        </>
    )
}

export default Quadril;