import React from "react";
import {NavLink} from "react-router-dom";
import Fisioterapia from "./fisioterapia";
import MedicoHospitalar from "./medico_hospitalar";
import Orteses from "./orteses";
import Proteses from "./proteses";

function Produtos() {
    return (
        <>
            <h2>Produtos</h2>
            <p>
                A São José Ortopédicos produz e revende produtos que respeitam os padrões de segurança e qualidade. Conheça nossas linhas:
            </p>
            <ul className="produtos">
                <li>
                    <NavLink className="produtos__item" to="/produtos/proteses">Próteses</NavLink>
                </li>
                <li>
                    <NavLink className="produtos__item" to="/produtos/orteses">Órteses</NavLink>
                </li>
                <li>
                    <NavLink className="produtos__item" to="/produtos/Fisioterapia">Fisioterapia</NavLink>
                </li>
                <li>
                    <NavLink className="produtos__item" to="/produtos/medico-hospitalar">Médico-hospitalar</NavLink>
                </li>
            </ul>
        </>
    );
}

const Page = {
    Home: Produtos,
    Fisioterapia,
    MedicoHospitalar,
    Orteses,
    Proteses
};

export default Page