import CadeirasRodas from "./cadeira_rodas";
import HomeCare from "./home_care";
import Infralux from "./infralux";
import Scooter from "./scooter";
import Andadores from "./andadores";
import Home from "./home";

const module = {
    Home,
    CadeirasRodas,
    HomeCare,
    Infralux,
    Scooter,
    Andadores
};

export default module;