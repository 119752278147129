import React from "react";
import imgOmbro1 from "../../../assets/produtos_proteses_ombro_1.jpg"
import imgOmbro2 from "../../../assets/produtos_proteses_ombro_2.jpg"
import imgOmbro3 from "../../../assets/produtos_proteses_ombro_3.jpg"
import imgOmbro4 from "../../../assets/produtos_proteses_ombro_4.jpg"
import imgOmbro5 from "../../../assets/produtos_proteses_ombro_5.jpg"
import imgOmbro6 from "../../../assets/produtos_proteses_ombro_6.jpg"
import imgOmbro7 from "../../../assets/produtos_proteses_ombro_7.jpg"
import imgOmbro8 from "../../../assets/produtos_proteses_ombro_8.jpg"
import imgOmbro9 from "../../../assets/produtos_proteses_ombro_9.jpg"

function Ombro() {
    return (
        <>
            <h2>Desarticulação de ombro</h2>
            <h3>Prótese para desarticulação de ombro</h3>
            <p>
                Além da desarticulação de ombro, existem níveis de amputação ainda mais altos nesta região.<br />
                No caso de uma amputação intertóraco-escapular, remove-se a escápula e a clavícula, assim como a musculatura correspondente.<br />
                Nestes casos, a protetização torna-se extremamente complexa pela falta de pontos de apoio para a adaptação e fixação do encaixe.<br />
                Para a protetização de cotos ao nível do ombro, pode- se utilizar próteses estéticas e funcionais.<br />
                Próteses funcionais requerem muita experiência de toda a equipe de reabilitação e um bom condicionamento do paciente.
            </p>
            <div className="images">
                <img src={imgOmbro1} alt="Ombro" className="image"/>
            </div>
            <h3>Prótese estética para desarticulação de ombro</h3>
            <p>
                Este tipo de prótese é indicado para a protetização de desarticulações de ombro e amputações intertóraco-escapulares, especialmente para pacientes que dispensam próteses funcionais ou têm dificuldades de adaptação às mesmas.<br />
                Estas próteses apresentam um peso reduzido, mas possuem uma função passiva muito limitada.<br />
                A confecção é feita com componentes modulares, que recebem um revestimento cosmético modelado de forma individual. encaixe de contato envolve parte do ombro, sendo fixado através de uma correia.<br />
                Em caso de perda substancial de tecidos, necessita- se de um encaixe adicional para a compensação de volume.<br />
                Uma articulação de ombro realiza a união ao braço, que por sua vez fixa o antebraço através de uma articulação de cotovelo.<br />
                Utiliza-se uma mão cosmética ou passiva, que é fixada ao antebraço através de diferentes tipos de adaptadores.<br />
                Uma luva cosmética reveste a mão. Sua forma, cor e estrutura proporcionam um aspecto natural a prótese.
            </p>
            <div className="images">
                <img src={imgOmbro6} alt="Ombro" className="image"/>
                <img src={imgOmbro7} alt="Ombro" className="image"/>
                <img src={imgOmbro8} alt="Ombro" className="image"/>
                <img src={imgOmbro9} alt="Ombro" className="image"/>
            </div>
            <h3>Prótese híbrida para desarticulação de ombro</h3>
            <p>
                Este tipo de prótese combina a força muscular do paciente e uma força externa para o acionamento da prótese, sendo indicado para a protetização de desarticulações de ombro e amputações intertóraco- escapulares.<br />
                Um pré-requisito é a disponibilidade de potenciais musculares suficientemente fortes para o acionamento da mão ou do gancho mioelétrico.<br />
                As condições do coto determinam o tipo de encaixe, a disposição dos eletrodos e do tirante.<br />
                Em caso de perda substancial de tecidos, utiliza-se um encaixe externo adicional para a compensação de volume.<br />
                Uma articulação de ombro realiza a união ao braço, que por sua vez fixa o antebraço através de uma articulação de cotovelo.<br />
                A flexão e o bloqueio do cotovelo são controlados através de um tirante.<br />
                Na parte terminal utiliza-se uma mão ou um gancho mioelétrico (Greifer), intercambiáveis graças a um punho de troca rápida.<br />
                A fonte de energia é um acumulador (bateria) recarregável de 6 Volts.
            </p>
            <div className="images">
                <img src={imgOmbro2} alt="Ombro" className="image"/>
                <img src={imgOmbro3} alt="Ombro" className="image"/>
                <img src={imgOmbro4} alt="Ombro" className="image"/>
                <img src={imgOmbro5} alt="Ombro" className="image"/>
            </div>
        </>
    )
}

export default Ombro;