import React from "react";
import imgPunho1 from "../../../assets/produtos_proteses_punho_1.jpg"
import imgPunho2 from "../../../assets/produtos_proteses_punho_2.jpg"
import imgPunho3 from "../../../assets/produtos_proteses_punho_3.jpg"
import imgPunho4 from "../../../assets/produtos_proteses_punho_4.jpg"
import imgPunho5 from "../../../assets/produtos_proteses_punho_5.jpg"
import imgPunho6 from "../../../assets/produtos_proteses_punho_6.jpg"
import imgPunho7 from "../../../assets/produtos_proteses_punho_7.jpg"
import imgPunho8 from "../../../assets/produtos_proteses_punho_8.jpg"
import imgPunho9 from "../../../assets/produtos_proteses_punho_9.jpg"
import imgPunho10 from "../../../assets/produtos_proteses_punho_10.jpg"
import imgPunho11 from "../../../assets/produtos_proteses_punho_11.jpg"

function Punho() {
    return (
        <>
            <h2>Desarticulação de punho</h2>
            <h3>Próteses para desarticulação de punho</h3>
            <p>
                Em caso de uma desarticulação de punho, o coto é relativamente longo, além de ser mais largo na região distal.<br />
                Estas características específicas possibilitam uma boa adaptação e fixação da prótese, e a parte distal do coto não deve ser corrigida cirurgicamente por razões estéticas.<br />
                Para a protetização de desarticulação de punho existem vários sistemas de próteses estéticas e funcionais.
            </p>
            <div className="images">
                <img src={imgPunho1} alt="Punho" className="image"/>
                <img src={imgPunho2} alt="Punho" className="image"/>
                <img src={imgPunho3} alt="Punho" className="image"/>
            </div>
            <h3>Prótese ativa para desarticulação de punho</h3>
            <p>
                Este tipo de prótese funcional, também chamado de braço ativo, possibilita a protetização da desarticulação de punho, especialmente quando não existem meios para colocação uma prótese mioelétrica.<br />
                Em comparação a uma prótese mioelétrica, ela tem vantagem de pesar menos e ser independente de uma fonte de energia.<br />
                Por outro lado, o emprego de uma tirante de tração representa uma limitação de movimentos e menor conforto de uso.<br />
                A fixação da mão ao encaixe é feita através de um chassi sem rosca, evitando um alongamento excessivo da prótese em relação ao membro não amputado.<br />
                Para a colocação de um gancho torna-se necessário um adaptador especial.
            </p>
            <div className="images">
                <img src={imgPunho4} alt="Punho" className="image"/>
                <img src={imgPunho5} alt="Punho" className="image"/>
                <img src={imgPunho6} alt="Punho" className="image"/>
                <img src={imgPunho7} alt="Punho" className="image"/>
            </div>
            <h3>Prótese estética para desarticulação</h3>
            <p>
                Este tipo de prótese possibilita a protetização de desarticulações de punho.<br />
                Ela é indicada para pacientes que dispensam ou não se adaptam as próteses funcionais.<br />
                As características específicas destas próteses são o peso reduzido e o manuseio simples, mas ela possui uma função passiva bastante restrita.<br />
                Devido ao alargamento da parte distal do coto, basta utilizar um encaixe de contato para a fixação segura da prótese; Ele deve terminar um pouco abaixo do cotovelo, permitindo um movimento livre de pronosupinação.<br />
                A mão é fixada ao encaixe externo, devendo-se evitar um alongamento excessivo da prótese.<br />
                Uma luva cosmética reveste a mão interna. Sua forma, cor e estrutura proporcionam um aspecto natural à prótese.
            </p>
            <div className="images">
                <img src={imgPunho8} alt="Punho" className="image"/>
                <img src={imgPunho9} alt="Punho" className="image"/>
                <img src={imgPunho10} alt="Punho" className="image"/>
                <img src={imgPunho11} alt="Punho" className="image"/>
            </div>
            <h3>Prótese mioelétrica</h3>
            <p>
                Do ponto de vista funcional, a prótese mioelétrica é a mais indicada para desarticulações de punho.<br />
                O principal pré-requisito para este tipo de prótese é um potencial suficientemente forte para o controle da mão, que é emitido através da contração muscular.<br />
                Devido ao alargamento da parte distal do coto, basta utilizar um encaixe de contato para a fixação da prótese.<br />
                Ele termina pouco abaixo do cotovelo, e permite um movimento livre de prono-suspinação. encaixe externo cobre os cabos e eletrodos do sistema mioelétrico, além de receber o acumulador (bateria).<br />
                A fixação da mão mioelétrica ou do gancho elétrico (Greifer) ao encaixe é feita através de um anel de laminação.
            </p>
        </>
    )
}

export default Punho;