import database from "../../../services/database";
import IconButton from "../../../components/icon-button";
import React from "react";

function Home() {
    return (
        <>
            <h2>{database.orteses.title}</h2>
            <p>
                {database.orteses.description}
            </p>
            {
                <ul className="icones">
                    {
                        database.orteses.items.map(y => {
                            return (
                                <li>
                                    <IconButton
                                        title={y.title}
                                        icon={y.icon}
                                        path={y.path}
                                        iconLegend={y.iconLegend}
                                    />
                                </li>
                            );
                        })
                    }
                </ul>
            }
        </>
    );
}

export default Home;