import React from "react";
import imgOmbro1 from "../../../assets/produtos_orteses_ombro_1.jpg"
import imgOmbro2 from "../../../assets/produtos_orteses_ombro_2.jpg"

function Ombro() {
    return (
        <>
            <h2>Ombro</h2>
            <h3>Abdução de ombro de recém nascidos</h3>
            <p>
                Indicações:<br />
                Indicada para tratamento de luxação ou sub-luxação de ombro de crianças até 6 meses de idade.
            </p>
            <p>
                Características:<br />
                Confeccionada após molde gessado da criança.<br />
                Moldada em polipropileno forrado com espuma Evazote e fechos em velcro.
            </p>
            <div className="images">
                <img className="image" src={imgOmbro1} alt="Ombro"/>
                <img className="image" src={imgOmbro2} alt="Ombro"/>
            </div>
        </>
    )
}

export default Ombro;