import React from "react";
import imgDiabetico1 from "../../../assets/produtos_orteses_diabetico_1.jpg"
import imgDiabetico2 from "../../../assets/produtos_orteses_diabetico_2.png"
import imgDiabetico3 from "../../../assets/produtos_orteses_diabetico_3.jpg"
import imgDiabetico4 from "../../../assets/produtos_orteses_diabetico_4.jpg"
import imgDiabetico5 from "../../../assets/produtos_orteses_diabetico_5.jpg"
import imgDiabetico6 from "../../../assets/produtos_orteses_diabetico_6.jpg"
import imgDiabetico7 from "../../../assets/produtos_orteses_diabetico_7.jpg"
import imgDiabetico8 from "../../../assets/produtos_orteses_diabetico_8.jpg"
import imgDiabetico9 from "../../../assets/produtos_orteses_diabetico_9.jpg"
import imgDiabetico10 from "../../../assets/produtos_orteses_diabetico_10.jpg"
import imgDiabetico11 from "../../../assets/produtos_orteses_diabetico_11.jpg"
import imgDiabetico12 from "../../../assets/produtos_orteses_diabetico_12.jpg"

function Diabeticos() {
    return (
        <>
            <h2>Para diabéticos</h2>
            <h3>Meias especiais para pés sensíveis ou diabéticos</h3>
            <div className="images">
                <img className="image image--large" src={imgDiabetico1} alt="Diabético"/>
                <img className="image" src={imgDiabetico2} alt="Diabético"/>
                <img className="image" src={imgDiabetico3} alt="Diabético"/>
            </div>
            <h3>Calçados especiais para pés sensíveis ou diabéticos</h3>
            <div className="images">
                <img className="image image--large" src={imgDiabetico4} alt="Diabético"/>
                <img className="image image--large" src={imgDiabetico5} alt="Diabético"/>
                <img className="image image--large" src={imgDiabetico6} alt="Diabético"/>
                <img className="image image--large" src={imgDiabetico7} alt="Diabético"/>
                <img className="image image--large" src={imgDiabetico8} alt="Diabético"/>
                <img className="image image--large" src={imgDiabetico9} alt="Diabético"/>
                <img className="image image--large" src={imgDiabetico10} alt="Diabético"/>
            </div>
            <h3>Palmilhas de silicone</h3>
            <div className="images">
                <img className="image" src={imgDiabetico11} alt="Diabético"/>
                <img className="image" src={imgDiabetico12} alt="Diabético"/>
            </div>
        </>
    )
}

export default Diabeticos;