import React from "react";
import WhatsappIcon from '../assets/whatsapp_icon.png';
import axios from "axios";
import {useForm} from "react-hook-form";

function Contact() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = async (email) => {
    const response = await axios.post('https://api-s.ceru.com.br/utilities/email', {
      ...email,
      from: 'atendimento@saojoseortopedicos.com.br',
      to: ['tiagor87@gmail.com', 'atendimento@saojoseortopedicos.com.br']
    });

    if (response.status === 200) {
      reset();
      alert('Mensagem enviada com sucesso');
      return;
    }

    alert('Ocorreu um erro, tente novamente.');
  }
  
  
  return (
    <>
      <h2 className="contact-title">Contato</h2>
      <div className="contact-container">
        <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Seu nome<sup>*</sup></label>
            <input className={errors.name ? 'invalid' : null} type="text" {...register('name', { required: true })}/>
            <span className="error">Nome é obrigatório</span>
          </div>
          <div className="form-group">
            <label htmlFor="email">Seu email<sup>*</sup></label>
            <input className={errors.email ? 'invalid' : null} type="email" {...register('email', { required: true })}/>
            <span className="error">Email é obrigatório</span>
          </div>
          <div className="form-group">
            <label htmlFor="phone">Seu telefone</label>
            <input type="phone" {...register('phone')}/>
          </div>
          <div className="form-group">
            <label htmlFor="subject">Assunto</label>
            <input type="text" {...register('subject')}/>
          </div>
          <div className="form-group">
            <label htmlFor="message">Sua mensagem<sup>*</sup></label>
            <textarea className={errors.message ? 'invalid' : null} rows="10" {...register('message', { required: true })}></textarea>
            <span className="error">Mensagem é obrigatório</span>
          </div>

          <button type="submit" className="button">Enviar</button>
        </form>
        <div className="contact-map">
          <iframe title="Mapa de endereços"
                  className="contact-map-iframe"
                  src="https://www.google.com/maps/d/u/1/embed?mid=1sQ3gKq5PXPQrNS2mhYCHMCZVv2GFb2Bx"></iframe>
        </div>
      </div>
      <div className="contact-cities">
        <div className="contact-city">
          <h3 className="city-name">Teresópolis</h3>
          <p>
            Rua Tenente Luiz Meireles, 200, Centro<br/>
            CEP: 25955-001<br/>
            Tel: (21) 2742-9932<br/>
            <img src={WhatsappIcon} alt="whatsapp"/><span>+55 21 98802-0192</span>
          </p>
        </div>
        <div className="contact-city">
          <h3 className="city-name">Nova Friburgo</h3>
          <p>Rua Marques Braga, 4 / Loja D, Centro<br/>
            CEP: 28610-210<br/>
            Tel: (22) 2523-1535<br/>
            <img src={WhatsappIcon} alt="whatsapp"/><span>+55 22 98117-4444</span>
          </p>
        </div>
        <div className="contact-city">
          <h3 className="city-name">Cabo Frio</h3>
          <p>Rua Rui Barbosa, 570, Centro<br/>
            CEP:28.907-420<br/>
            Tel: (22) 2644-1397<br/>
            <span><img src={WhatsappIcon} alt="whatsapp"/> +55 22 98133-0439</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Contact;