import database from "../../../services/database";
import IconButton from "../../../components/icon-button";
import React from "react";

function Home() {
    return (
        <>
            <h2>{database.proteses.title}</h2>
            <p>
                {database.proteses.description}
            </p>
            {
                database.proteses.items.map(x => {
                    return (
                        <>
                            <h3>{x.title}</h3>
                            <ul className="icones">
                                {
                                    x.items.map(y => {
                                        return (
                                            <li>
                                                <IconButton
                                                    title={y.title}
                                                    icon={y.icon}
                                                    path={y.path}
                                                    iconLegend={y.iconLegend}
                                                />
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </>
                    )
                })
            }
        </>
    );
}

export default Home;