import React from "react";
import iconPodologia from "../assets/servicos_podologia.png";
import iconManutencao from "../assets/servicos_manutencao.png";
import imgPodologia from "../assets/servicos_calista_pedicuro.jpg";
import imgManutencao from "../assets/servicos_manutencao_oficina.jpg";

function Servicos() {
    return (
        <>
            <h2>Serviços</h2>
            <div className="display--flex">
                <div className="servicos_group margin--bottom">
                    <img className="servicos_img" src={iconPodologia} alt="Serviços de podologia"/>
                    <h3>Podologia (calista e pedicuro)</h3>
                    <p>
                        Tratamento de Calosidades nos pés (calos e cravos)
                        <br />
                        Tratamento de Unhas Encravadas (sistema tradicional e com fibra)
                        <br />
                        Massagem Relaxante para os pés
                        <br />
                    </p>
                    <img className="image image--large" src={imgPodologia} alt="Temos calista e pedicuro"/>
                </div>
                <div className="servicos_group">
                    <img className="servicos_img" src={iconManutencao} alt="Serviços de manutenção"/>
                    <h3>Faça manutenção periódica de sua prótese</h3>
                    <p>
                        A São José Ortopédicos também conta com uma Oficina Ortopédica totalmente equipada para produção de qualquer aparelho ortopédico (órteses e próteses), estrategicamente situada em Teresópolis.
                        <br/>
                        Nesta oficina estamos capacitados a efetuar qualquer conserto e manutenção em suas próteses e/ou órteses.
                        <br/>
                        Conte sempre com a São José Ortopédicos.
                    </p>
                    <img className="image image--large" src={imgManutencao} alt="Oficina ortopédica para produção e manutenção de qualquer aparelho"/>
                </div>
            </div>
        </>
    );
}

export default Servicos;