import React from "react";

function Andadores() {
    return (
        <>
            <h2>Andadores e cadeiras especiais</h2>
            <h3>Andadores</h3>
            <p>Completa linha para facilidade de locomoção</p>
            <ul>
                <li>Estrutura tubular em aco , pintura epoxi , rodas dianteiras giratorias e traseiras fixas em polipropileno com freios bilaterais</li>
                <li>Apoio de mao com manoplas anatomicas em forma de guidao com regulagem de altura</li>
                <li>Apoio para assento em courvin reforcado em formato de fralda ou na forma de rolinho abdutor , com regulagem de altura</li>
                <li>Todas regulagems são feitas por intermedio de manipulos que permitem ajuste facil e rapido</li>
                <li>Possui sistema anti tombo</li>
            </ul>
            <p>Opcionais:</p>
            <ul>
                <li>Apoio de bracos em formato de calha com regulagem de altura e profundidade</li>
                <li>Anti tombo lateral</li>
                <li>Qualquer adaptação que o profissional indicar</li>
            </ul>
            <p>Observações:</p>
            <ul>
                <li>Produto registrado Ministerio da Saúde</li>
                <li>Feito som medida e indicação de um profissional</li>
                <li>Garantia 12 meses</li>
            </ul>
            <h3>Carrinho Jupiter infantil</h3>
            <ul>
                <li>Estrutura tubular em aço ou aluminio reforçado, dobravel</li>
                <li>Pintura epoxi</li>
                <li>Rodas traseiras fixas aro 16, pneus infláveis, rodas dianteiras giratorias aro 5. Quando a estrutura for em aluminio, as rodas serão com eixo quick</li>
                <li>Cinto de seguranca peitoral tipo camiseta em tecido automotivo com regulagens</li>
                <li>Apoio de pés rigido , planos estofados com tecido automotivo regulavel com cintas para os pés</li>
                <li>Assento e encosto rígido estofado em tecido automotivo ,sobmedida</li>
                <li>Apoio de tronco e quadril independentes, ajustaveis, sobmedida em espuma injetadap>Abdutor rigido, estofado e regulavel</li>
                <li>Apoio de cabeça em espuma injetada, estofado, com regulagens</li>
                <li>Assento e encosto podem ser retirados e colocado no banco do carro, reclinavel</li>
                <li>Apoio de braço rigido, estofado , sob medida , regulavel</li>
                <li>Possuí sacola para acondicionamento de pertences</li>
                <li>Tamanho: sob medida</li>
            </ul>
            <h3>Estabilizador vertical Erectus</h3>
            <ul>
                <li>Estrutura em aco tubular reforçada</li>
                <li>Anti tombo traseiro ,frontal e lateral com regulagens</li>
                <li>Pintura epoxi</li>
                <li>Apoios rigidos estofados para peitoral e cintura com cintas regulaveis em nylon 600 reforçado</li>
                <li>Apoio para pernas estofado em formato anatomico com regulagens</li>
                <li>Mesa em formica com rebordo lateral com regulagens</li>
                <li>Reclinavel ( horizontal até vertical)</li>
                <li>Apoio para pés em e.v.a. com cintas de fixacao estofadas</li>
                <li>Apoio de cabeça estofado anatomico com regulagens</li>
                <li>Cinto para ombros com regulagens</li>
            </ul>
            <h3>Cadeira de rodas Jupiter relax</h3>
            <ul>
                <li>Estrutura em aco ou aluminio tubular monobloco</li>
                <li>Pintura epoxi</li>
                <li>Rodas traseiras alumínio aro 20 com eixo quick , pneus inflaveis e rodas dianteiras giratorias aro 5 com eixo quick</li>
                <li>Assento e encosto rigido estofado em tecido automotivo sobmedida</li>
                <li>Apoio anatomicos independentes em espuma injetada, estofados para troncos e quadris sob medida com regulagens</li>
                <li>Assento e encosto são independentes , permitindo assim reclinar o assento independente do encosto variando o angulo entre eles</li>
                <li>Apoio de cabeca rigido estofado com regulagens</li>
                <li>Apoio de pes planos com cintas para os pes regulavel na altura</li>
                <li>Apoio de braco estofado regulavel na altura</li>
                <li>Freios bilaterais</li>
            </ul>
            <h3>Concha para carro</h3>
            <ul>
                <li>Estrutura em aço tubular</li>
                <li>Pintura epoxi</li>
                <li>Assento e encosto rigido , estofado em tecido automotivo feitos sobmedida</li>
                <li>Apoio de cabeça estofado em espuma injetada com regulagens</li>
                <li>Abdutor de pernas rigido , estofado com regulagens</li>
                <li>Apoios anatomicos, independentes, estofados, para troncos e quadris com regulagens</li>
                <li>Cinto de seguranca peitoral em tecido automotivo com regulagens</li>
                <li>Observações</li>
                <ul>
                    <li>Assento e encosto em formato anatomico</li>
                    <li>Qualquer outra adaptacao que o profissional indicar</li>
                </ul>
            </ul>
        </>
    )
}

export default Andadores;