import React from "react";

function Mama() {
    return (
        <>
            <h2>Próteses para mama</h2>
            <h3>Prótese externa de mama</h3>
            <p>
                Indicações:<br />
                Indicada para Pós-mastectomia;<br />
                Pode ser utilizada em qualquer sutiã normal;<br />
                Tem peso, mobilidade e consistência de um seio natural;<br />
                Possui a vantagem de remover gradualmente e prevenir cicatrizes de pós- cirurgia, com o auxilio do oléo mineral que migra do gel para a pele;<br />
                Pode ser colorida e com menor custo.
            </p>
            <p>
                Composição:<br />
                Gel Polímero
            </p>
        </>
    )
}

export default Mama;