import React from "react";
import imgBraco1 from "../../../assets/produtos_proteses_braco_1.jpg";
import imgBraco2 from "../../../assets/produtos_proteses_braco_2.jpg";
import imgBraco3 from "../../../assets/produtos_proteses_braco_3.jpg";
import imgBraco4 from "../../../assets/produtos_proteses_braco_4.jpg";
import imgBraco5 from "../../../assets/produtos_proteses_braco_5.jpg";
import imgBraco6 from "../../../assets/produtos_proteses_braco_6.jpg";
import imgBraco7 from "../../../assets/produtos_proteses_braco_7.jpg";
import imgBraco8 from "../../../assets/produtos_proteses_braco_8.jpg";
import imgBraco9 from "../../../assets/produtos_proteses_braco_9.jpg";
import imgBraco10 from "../../../assets/produtos_proteses_braco_10.jpg";
import imgBraco11 from "../../../assets/produtos_proteses_braco_11.jpg";
import imgBraco12 from "../../../assets/produtos_proteses_braco_12.jpg";


function Braco() {
    return (
        <>
            <h2>Amputação de braço</h2>
            <h3>Prótese para amputação de braço</h3>
            <p>
                Nas amputações de braço deve-se conservar ao máximo partes ósseas e tecidos.<br />
                Quanto maior o comprimento do coto, melhor é a adaptação e fixação da prótese.<br />
                Coto possui a função de uma alavanca, sendo essencial para o controle da prótese.<br />
                Existem vários sistemas de próteses estéticas e funcionais para este nível de amputação.
            </p>
            <h3>Prótese ativa para amputação de braço</h3>
            <p>
                Este tipo de prótese funcional, também chamado de braço ativo, possibilita a protetização de amputações de braço de diferentes níveis, especialmente quando não há possibilidade da colocação de uma prótese mioelétrica.<br />
                Esta prótese possui um peso menor em comparação a próteses com uma fonte de energia externa.<br />
                Por outro lado, um tirante para o acionamento do cotovelo e da mão reduz o conforto de uso.<br />
                A eficiência do tirante depende da mobilidade do ombro, do comprimento do coto, da condição física (muscular) do paciente, etc.<br />
                Estes fatores também delimitam a forma anatômica do encaixe de contato. encaixe externo e a parte distal da prótese estão interligados através de uma articulação de cotovelo (com ou sem trava).<br />
                Como peça terminal utilizam-se mãos ativas ou ganchos, que são intercambiáveis graças a diferentes tipos de punhos.<br />
                A prótese é controlada através de tirantes, acionados pelos movimentos do ombro e do coto. O tirante triplo controla a mão (ou gancho), a flexão do cotovelo e a sua trava.
            </p>
            <div className="images">
                <img src={imgBraco1} alt="Braço" className="image"/>
                <img src={imgBraco2} alt="Braço" className="image"/>
            </div>
            <h3>Prótese mioelétrica para amputação de braço</h3>
            <p>
                Este tipo de prótese é indicado para a protetização de cotos de diferentes comprimentos.<br />
                Um pré-requisito é a disponibilidade de potenciais musculares suficientemente fortes para o acionamento dos componentes elétricos.<br />
                A fixação é feita através de um encaixe de contato, preso através de uma correia.<br />
                Os eletrodos possuem uma suspensão elástica, e recebem os sinais do bíceps e do tríceps.<br />
                Estes sinais são transformados em quatro impulsos distintos, através de uma unidade de acionamento, e direcionados à mão e ao cotovelo elétrico. encaixe externo cobre os cabos e eletrodos, e fixa a parte distal da prótese através da articulação de cotovelo.<br />
                Utiliza-se uma mão ou um gancho mioelétrico (Greifer) como peça terminal, que são intercambiáveis através de diferentes tipos de punhos.
            </p>
            <div className="images">
                <img src={imgBraco3} alt="Braço" className="image"/>
                <img src={imgBraco4} alt="Braço" className="image"/>
                <img src={imgBraco5} alt="Braço" className="image"/>
                <img src={imgBraco6} alt="Braço" className="image"/>
            </div>
            <h3>Prótese estética para amputação de braço</h3>
            <p>
                Este tipo de prótese pode ser utilizado para a protetização de diferentes comprimentos de coto.<br />
                Ele é indicado para pacientes que dispensam ou não se adaptam as próteses funcionais.<br />
                As suas principais características são: peso reduzido e manuseio simples, mas possui uma função passiva muito restrita.<br />
                Esta prótese normalmente é confeccionada com componentes modulares, recebendo um acabamento individualizado através do revestimento cosmético (espuma).<br />
                A fixação da prótese é feita através de um encaixe de contato envolvendo parte do ombro, sendo fixado com o auxílio de uma correia.<br />
                A união entre o encaixe externo e a parte distal da prótese é feita através de uma articulação de cotovelo.<br />
                Utiliza-se uma mão cosmética ou uma mão de sistema passiva, fixada ao antebraço através de diferentes tipos de punhos.<br />
                Uma luva cosmética reveste a mão. Sua forma, cor e estrutura proporcionam um aspecto natural a prótese.
            </p>
            <div className="images">
                <img src={imgBraco7} alt="Braço" className="image"/>
                <img src={imgBraco8} alt="Braço" className="image"/>
                <img src={imgBraco9} alt="Braço" className="image"/>
                <img src={imgBraco10} alt="Braço" className="image"/>
            </div>
            <h3>Prótese híbrida para amputação de braço</h3>
            <p>
                Este tipo de prótese combina a força muscular do paciente e uma força externa para o acionamento dos componentes, sendo utilizada na protetização de amputações de braço de diferentes níveis.<br />
                Um pré-requisito é a disponibilidade de potenciais musculares suficientemente fortes para o acionamento da mão ou do gancho mioelétrico.<br />
                A fixação é feita através de um encaixe de contato envolvendo parte do ombro, preso através de uma correia.<br />
                Os eletrodos são fixados ao encaixe através de uma suspensão elástica. encaixe externo cobre os eletrodos e cabos, e fixa o antebraço através de uma articulação de cotovelo mecânica com trava.<br />
                O tirante envolve o ombro e é responsável pelo controle de flexão e do bloqueio do cotovelo mecânico.<br />
                Utiliza-se uma mão ou um gancho mioelétrico (Greifer) como peça terminal, que são intercambiáveis através de diferentes tipos de punhos.<br />
                A fonte de energia é um acumulador (bateria) recarregável de 6 Volts.
            </p>
            <div className="images">
                <img src={imgBraco11} alt="Braço" className="image"/>
                <img src={imgBraco12} alt="Braço" className="image"/>
            </div>
        </>
    )
}

export default Braco;