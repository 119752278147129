import React from "react";
import imgCotovelo1 from "../../../assets/produtos_proteses_cotovelo_1.jpg"
import imgCotovelo2 from "../../../assets/produtos_proteses_cotovelo_2.jpg"
import imgCotovelo3 from "../../../assets/produtos_proteses_cotovelo_3.jpg"
import imgCotovelo4 from "../../../assets/produtos_proteses_cotovelo_4.jpg"

function Cotovelo() {
    return (
        <>
            <h2>Desarticulação do cotovelo</h2>
            <h3>Próteses para desarticulação de cotovelo</h3>
            <p>
                A protetização de desarticulações de cotovelo, um nível de amputação relativamente raro, possibilita uma boa suspensão da prótese graças à forma distal do coto, mais larga devido à presença dos epicôndilos.<br />
                Por esta razão não se deve corrigir esteticamente a parte distal do coto.<br />
                Existem vários sistemas de próteses estéticas e funcionais para este nível de amputação.
            </p>
            <div className="images">
                <img src={imgCotovelo1} alt="Ombro" className="image"/>
            </div>
            <h3>Prótese ativa para desarticulação de cotovelo</h3>
            <p>
                Este tipo de prótese funcional é utilizado para a protetização da desarticulação de cotovelo, sobretudo quando não é possível usar uma prótese mioelétrica.<br />
                Em comparação a prótese com força externa, ela tem a vantagem de pesar menos e ser independente de uma fonte de energia externa.<br />
                Por outro lado, o sistema de tirantes para o acionamento da prótese e o bloqueio do cotovelo limita o conforto de uso.<br />
                Devido ao alargamento da parte distal do coto ao nível dos epicôndilos, é suficiente utilizar apenas um encaixe de contato, que proporciona uma fixação segura e grande liberdade de movimentos do ombro.<br />
                A união do encaixe externo à prótese de antebraço é realizada por duas hastes laterais articuladas. A articulação pode ser livre ou possuir uma trava acionada por um tirante.<br />
                As funções da prótese são realizadas através de movimentos de tração de três tirantes.<br />
                Eles são responsáveis pelas seguintes funções: acionamento da mão ou do gancho, assim como flexão e bloqueio/desbloqueio da articulação de cotovelo.
            </p>
            <h3>Prótese estética para desarticulação do cotovelo</h3>
            <p>
                Este tipo de prótese é utilizado para a desarticulação de cotovelo, sobretudo para pacientes que recusam ou não se adaptam a uma prótese funcional.<br />
                As principais características desta prótese são o peso reduzido e seu manuseio simples, mas ela possui uma função passiva bastante limitada.<br />
                Ela pode ser usada para carregar objetos ou para auxiliar a outra mão. Alargamento da parte distal do coto permite a utilização de um encaixe de contato com uma suspensão muito segura, deixando livres os movimentos do ombro.<br />
                A união do encaixe externo à prótese de antebraço é realizada por duas hastes laterais articuladas.<br />
                A articulação pode ser livre ou possuir uma trava acionada por um tirante.<br />
                Utiliza-se uma mão estética ou passiva, que é fixada ao encaixe através de diferentes tipos de punhos.<br />
                Uma luva cosmética reveste a mão. Sua forma, estrutura e cor proporcionam um aspecto natural à prótese.
            </p>
            <div className="images">
                <img src={imgCotovelo2} alt="Cotovelo" className="image"/>
                <img src={imgCotovelo3} alt="Cotovelo" className="image"/>
                <img src={imgCotovelo4} alt="Cotovelo" className="image"/>
            </div>
            <h3>Prótese híbrida para desarticulação de cotovelo</h3>
            <p>
                Este tipo de prótese para desarticulação de cotovelo combina a força muscular do paciente e uma força externa (fonte de energia) para o acionamento dos componentes.<br />
                Um pré-requisito é a disponibilidade de potenciais musculares suficientemente fortes para o controle da mão ou do gancho mioelétrico.<br />
                Devido ao alargamento da parte distal do coto aos níveis dos epicôndilos, utiliza-se um encaixe de contato, que proporciona uma boa adaptação e fixação da prótese assim como grande liberdade de movimentos ao ombro.<br />
                Os eletrodos são fixados ao encaixe através de uma suspensão elástica. encaixe externo cobre os eletrodos e cabos.<br />
                A sua fixação à prótese de antebraço é feita através de duas hastes laterais articuladas.<br />
                As articulações podem ser livres ou possuir uma trava.<br />
                A flexão e o bloqueio do cotovelo são acionados através de um sistema de tirantes.<br />
                Como peça terminal utiliza-se uma mão mioelétrica ou um gancho (Greifer), que podem ser conectados ao encaixe através de diferentes tipos de punhos.<br />
                A fonte de energia é um acumulador (bateria) recarregável de 6 Volts.
            </p>
        </>
    )
}

export default Cotovelo;