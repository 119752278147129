import database from "../../../services/database";
import React from "react";

function Home() {
    return (
        <>
            <h2>{database.fisioterapia.title}</h2>
            {
                database.fisioterapia.items.map(x => {
                    return (
                        <>
                            <h3>{x.title}</h3>
                            <p>{x.description}</p>
                            <div className="images">
                                {
                                    x.images.map(y => {
                                        return (
                                            <img className="image" src={y.url} alt={y.legend} />
                                        );
                                    })
                                }
                            </div>
                        </>
                    );
                })
            }
        </>
    );
}

export default Home;