import React from "react";
import imgAcimaJoelho1 from "../../../assets/produtos_proteses_acima_joelho_1.jpg";
import imgAcimaJoelho2 from "../../../assets/produtos_proteses_acima_joelho_2.jpg";
import imgAcimaJoelho3 from "../../../assets/produtos_proteses_acima_joelho_3.jpg";
import imgAcimaJoelho4 from "../../../assets/produtos_proteses_acima_joelho_4.jpg";
import imgAcimaJoelho5 from "../../../assets/produtos_proteses_acima_joelho_5.jpg";

function AcimaDoJoelho() {
    return (
        <>
            <h2>Amputações acima do joelho</h2>
            <h3>Esclarecimentos e conselhos úteis, e Técnicas de Enfaixamento</h3>
            <p>
                Quase todos os amputados se sentem um tanto deprimidos imediatamente após a cirurgia, com exceção daqueles que sofreram dor intensa por determinado período antes da amputação. Esta depressão, normalmente, é logo substituída pelo desejo de se retornar a uma vida ativa.<br /><br />
                Os curativos permanece, em média, por um período de 15 dias aproximadamente, prazo em que normalmente acontece a cicatrização. Logo após a total cicatrização, ataduras elásticas são usadas para ajudar a circulação e evitar, ou reduzir edemas (inchaço). As ataduras elásticas são removidas e recolocadas ao longo do dia (instruções para aplicação de ataduras elásticas são dadas na próxima seção).<br /><br />
                Além dos curativos específicos utilizados pela equipe médica, exercícios orientados por profissionais especializados, são extremamente importantes para prevenir as contraturas musculares pois, quando isto acontece, dificulta, ou mesmo, impede o uso eficiente da prótese.<br /><br />
                Alguns “Conselhos do que NÃO FAZER” ajudarão a prevenir contraturas musculares, são mostrados abaixo.<br /><br />
                É muito importante que os exercícios prescritos pelo profissional habilitado sejam cumpridos regularmente e as posições mostradas no quadro sejam evitadas a fim de ser obtida uma performance melhor com o uso das próteses.
            </p>
            <div className="images">
                <img src={imgAcimaJoelho1} alt="Acima do joelho" className="image image--large"/>
            </div>
            <p>
                No geral, quanto mais cedo é colocada a prótese, melhor para o amputado. Um dos problemas mais difíceis no amputado é o EDEMA ou inchaço do coto, devido ao acúmulo de fluídos. O EDEMA estará presente, até certo ponto, em todos os casos e isto faz com que a colocação da prótese seja difícil, mas certas medidas podem ser tomadas para reduzir a quantidade de EDEMA. Depois que ocorre a total cicatrização do coto, ataduras elásticas são utilizadas para impedir o desenvolvimento e reduzir o EDEMA. Técnicas de Enfaixamento são apresentadas a seguir.<br /><br />
                Para cotos acima do joelho utiliza-se ataduras elásticas de 10cm de largura com 3,9m de comprimento e para cotos mais volumosos, pode ser necessária a utilização de mais de uma atadura elástica. Durante a utilização, as ataduras devem ser esticadas cerca de metade do limite de sua elasticidade, e a maior tensão deve ser em torno do final do coto, decrescendo ao aproximar-se do joelho. É de extrema importância envolver o quadril com a atadura (conforme demostrado) para evitar que o enfaixamento fique descendo e soltando.<br /><br />
                O coto deve ser mantido atado o tempo todo, mas o enfaixamento deve ser refeito a cada três ou quatro horas. O enfaixamento nunca deve ser mantido por mais de 12 horas sem renova-lo. Se dores latejantes ou sensação de dormência ocorrerem, o enfaixamento deve ser removido e recolocado. Normalmente, o latejamento ou sensação de dormência no coto estão relacionados a um enfaixamento com muita pressão.O EDEMA volta a ocorrer rapidamente quando o coto é deixado sem enfaixamento, então é muito importante recoloca-lo sem demora. À noite pode-se fazer um enfaixamento levemente com menos pressão, mas nunca se deve dormir sem a faixa, se esta estiver saindo durante a noite é sinal de que a pressão está sendo muito pouca.<br /><br />
                Meias compressivas especiais apropriadas para uso, ao invés de ataduras elásticas, embora não consideradas por algumas, podem ser utilizadas por aqueles pacientes que não consigam utilizar as ataduras elásticas.<br /><br />
                Se atadura elástica ou meia compressiva é usada, esta deve ser removida pelo menos 3 vezes ao dia e o coto deve ser massageado vigorosamente, por 10 a 15 minutos. A atadura ou meia devem ser recolocadas imediatamente após a massagem.
            </p>
            <div className="images">
                <img src={imgAcimaJoelho2} alt="Acima do joelho" className="image image--large"/>
            </div>
            <h3>Próteses convencionais</h3>
            <p>
                Este tipo de prótese é indicado para a protetização acima do joelho, para praticamente todos comprimentos de coto, porém, é muito pouco utilizada atualmente devido ao peso e a oferecer poucos recursos ao usuário.<br /><br />
                Ela é utilizada quando situações adversas (determinadas atividades do paciente, condições geográficas, etc.) não permitem o uso de próteses modulares.<br /><br />
                A prótese é composta pelo encaixe, a articulação do joelho com panturrilha, o tornozelo e o pé, que em conjunto determinam a aparência estética.
            </p>
            <div className="images">
                <img src={imgAcimaJoelho3} alt="Acima do joelho" className="image"/>
            </div>
            <h3>Próteses de banho</h3>
            <p>
                A prótese de banho para amputações acima do joelho é toda confeccionada em resina, resistente à água e utiliza um tipo de joelho apropriado para imersão em água.<br /><br />
                O encaixe de contato total possui uma válvula.
            </p>
            <div className="images">
                <img src={imgAcimaJoelho4} alt="Acima do joelho" className="image"/>
            </div>
            <h3>Próteses modulares</h3>
            <p>
                Este tipo de prótese é indicado para a protetização acima do joelho, para praticamente todos comprimentos de coto, e possui vantagens em relação a próteses convencionais tanto do ponto de vista funcional como cosmético.<br /><br />
                O encaixe, a articulação de joelho e o pé são ligados através de diferentes tipos de adaptadores.<br /><br />
                Existem diversos tipos de joelhos e pés que vão atender às necessidades individuais dos usuários plenamente.
            </p>
            <div className="images">
                <img src={imgAcimaJoelho5} alt="Acima do joelho" className="image"/>
            </div>
        </>
    )
}

export default AcimaDoJoelho;