import React from "react";

function MembrosInferiores() {
    return (
        <>
            <h2>Membros inferiores</h2>
            <h3>Imobilizador de Joelho Posição Funcional</h3>
            <p>
                Indicações:<br />
                Instabilidade de artrose e artríte reumatóide. Afeccções traumáticas do joelho em substituição ao aparelho gessado.<br />
                Treinos de ortostatismo em lesões paralíticas dos membros inferiores.
            </p>
            <p>
                Características:<br />
                Confeccionado em tecido dublado em espuma; Barbatanas posteriores em duralumínio;<br />
                Barbatanas laterais de polipropileno removíveis;<br />
                Cinta elástica para compressão do joelho;<br />
                Fecho em velcro.
            </p>
            <h3>Imobilizador Parcial do Joelho</h3>
            <p>
                Indicações:<br />
                Afecções traumáticas do joelho;<br />
                Prevenção da recidiva no retorno às atividades;<br />
                Reeducação da marcha em sequelas da AVC, artroses, artríte reumatóide.
            </p>
            <p>
                Características:<br />
                Confeccionado em tecido duplado em espuma;<br />
                Barbatanas posteriores em duralumínio;<br />
                Barbatanas frontais em polipropileno;<br />
                Cinta elástica para compressão do joelho.
            </p>
            <h3>Imobilizador de tornozelo</h3>
            <p>
                Indicações:<br />
                Entorses de tornozelo substituí a goteira gessada.<br />
                Prevenção e tratamento das deformidades da artríte reumatóide, usada em atividades esportivas retirando-se as barbatanas dorsais.
            </p>
            <p>
                Características:<br />
                Tecido sintético e resistente com espuma;<br />
                Barbatanas laterais em Duralumínio para moldar no tornozelo;<br />
                Fecho em velcro;<br />
                Lavável.
            </p>
            <h3>Joelheira Articulada Elástica</h3>
            <p>
                Indicações:<br />
                Afecções traumáticas do joelho, instabilidades látero-mediais não muito graves, artroses, artríte reumatóide e outras afecções que necessitem de apoio e compressão.<br />
                Prevenção da recidiva na prática esportiva.
            </p>
            <p>
                Características:<br />
                Confeccionada em elástico macio e resistente;<br />
                Barbatanas lateral e medial articuláveis de Duralumínio;<br />
                Fecho em velcro;<br />
                Lavável.
            </p>
            <h3>Estabilizador de tornozelo</h3>
            <p>
                Indicações:<br />
                Evitar artroses, melhorando a estabilidade da articulação do tornozelo na prática esportiva. Tratamento de entorses leves;<br />
                Prevenção precoce das deformidades da artríte reumatóide;<br />
                Auxiliar na marcha dos pacientes com dorso flexores dos pés.
            </p>
            <p>
                Características:<br />
                Lona dublada em algodão;<br />
                Barbatanas laterais em plástico;<br />
                Cinta elástica cruzada;<br />
                Fecho em velcro;<br />
                Lavável.
            </p>
            <h3>Sandália para Gesso</h3>
            <p>
                Indicações:<br />
                Apoio e proteção de botas gessadas quando permitida a marcha;<br />
                Uso nas deformidades permanentes dos pés e dedos, causados pela artríte reumatóide.
            </p>
            <p>
                Características:<br />
                Solado em EVA em discreta cruva;<br />
                Laterais em tecido sintético resistente;<br />
                Adaptável a ambos os pés;<br />
                Fecho em velcro;<br />
                Lavável
            </p>
            <h3>Tira Sub-Patelar em Tubo</h3>
            <p>
                Indicações:<br />
                Tendinites do tendão patelar (joelho saltador), alívio da tensão na inserção do tendão patelar.
            </p>
            <p>
                Características:<br />
                Confeccionada em tecido dublado em espuma;<br />
                Tubo de microespuma revestido e estofado;<br />
                Ajuste em velcro;<br />
                Lavável.
            </p>
        </>
    )
}

export default MembrosInferiores;