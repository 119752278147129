import React from "react";

function CadeirasRodas() {
    return (
        <>
            <h2>Cadeira de rodas</h2>
            <h3>Motorizada</h3>
            <p>Venda e assistência técnica autorizada das marcas: Freedom e Ottobock</p>
            <h3>Manual</h3>
            <p>Trabalhamos com Cadeiras de rodas de todos os modelos inclusive Cadeiras de Rodas feitas sob medida.
               Somos Distribuidores dos seguintes fabricantes: Ottobock, Vanzetti, Jaguaribe, Freedom e Ortobras.</p>
        </>
    )
}

export default CadeirasRodas;