import Mama from "./mama";
import Quadril from "./quadril";
import Joelho from "./joelho";
import Ombro from "./ombro";
import Cotovelo from "./cotovelo";
import Punho from "./punho";
import MaosEDedos from "./maos_e_dedos";
import Braco from "./braco";
import Antebraco from "./antebraco";
import AcimaDoJoelho from "./acima_do_joelho";
import AbaixoDoJoelho from "./abaixo_do_joelho";
import Home from "./home";

const module = {
    Home,
    Mama,
    Quadril,
    Joelho,
    Ombro,
    Cotovelo,
    Punho,
    MaosEDedos,
    Braco,
    Antebraco,
    AcimaDoJoelho,
    AbaixoDoJoelho
};

export default module;