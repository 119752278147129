import React from "react";

function Scooter() {
    return (
        <>
            <h2>Scooter</h2>
            <h3>Carrinhos motorizados</h3>
            <p>
                O Scooter é compacto, confortável, fácil de dirigir e transportar. Proporciona maior autonomia na locomoção para trabalhar, fazer compras ou passear. Pode ser utilizado em áreas internas e externas.<br /><br />
                Velocidade de 0 a 8 km/h monitorada linearmente por drive de potência eletrônico PWN ativado por chave de ignição e controlado por gatilho no guidom.<br /><br />
                Autonomia de 30 km por carga de bateria (uso ideal, 15 km), que são recarregáveis em um período máximo de 8 hs através de um carregador inteligente, multivoltagem (90 / 260 V), microprocessado, que efetua todas as curvas corretas de carga com consumo infimo de energia elétrica.<br /><br />
                Possuem estrutura em aço tubular, pneus infláveis, banco giratório com suspensão pneumática, braços e direção articulados que facilitam o acesso.
            </p>
        </>
    )
}

export default Scooter;