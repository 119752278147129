import React from "react";
import Cards from "../components/cards";

function Home() {
  return (
    <>
      <Cards />
    </>
  );
}

export default Home;