import React from "react";
import imgJoelho1 from "../../../assets/produtos_proteses_joelho_1.jpg"
import imgJoelho2 from "../../../assets/produtos_proteses_joelho_2.jpg"
import imgJoelho3 from "../../../assets/produtos_proteses_joelho_3.jpg"
import imgJoelho4 from "../../../assets/produtos_proteses_joelho_4.jpg"
import imgJoelho5 from "../../../assets/produtos_proteses_joelho_5.jpg"

function Joelho() {
    return (
        <>
            <h2>Próteses para joelho</h2>
            <h3>Próteses para desarticulações Desarticulações do joelho</h3>
            <p>
                Indicações:<br />
                Esta prótese é indicada para a protetização da desarticulação de joelho, um nível de amputação que ganhou importância nos últimos anos.
            </p>
            <p>
                Características:<br />
                Devido às ótimas características do coto, possibilitando boa descarga de peso na parte distal (côndilos), normalmente não é necessário o apoio na tuberosidade isquiática e tampouco na perimetria do coto . Para este nível de amputação só podem ser usadas articulações de joelho específicas, visando manter o alinhamento da articulação do joelho no mesmo nível contralateral.<br />
                A conexão entre o joelho e o pé é feita através de diferentes tipos de adaptadores. O revestimento cosmético em espuma recebe um acabamento individualizado, dando um aspecto mais natural à prótese.
            </p>
            <div className="images">
                <img src={imgJoelho1} alt="Joelho" className="image"/>
                <img src={imgJoelho2} alt="Joelho" className="image"/>
                <img src={imgJoelho3} alt="Joelho" className="image"/>
                <img src={imgJoelho4} alt="Joelho" className="image"/>
                <img src={imgJoelho5} alt="Joelho" className="image"/>
            </div>
        </>
    )
}

export default Joelho;