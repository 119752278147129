import React from "react"
import {NavLink} from "react-router-dom";
import "./icon-button.css";

function IconButton({ title, path, icon, iconLegend }) {
    if (!path)
    {
        return (
            <div className="icon_button">
                <img className="icon_button__img" src={icon} alt={iconLegend}/>
                {title}
            </div>
        );
    }

    return (
        <NavLink className="icon_button" to={path}>
            <img className="icon_button__img" src={icon} alt={iconLegend}/>
            {title}
        </NavLink>
    );
}

export default IconButton;