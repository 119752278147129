import React from "react";
import teresopolis from "../assets/quem_somos_teresopolis.jpg";
import proteses from "../assets/quem_somos_proteses.jpg";

function QuemSomos() {
    return (
        <>
            <h2>Quem somos</h2>
            <div className="display--flex flex--center">
                <img className="quem-somos__img margin--right" src={teresopolis} alt="Vista da cidade de Teresópolis"/>
                <p>
                    A São José Ortopédicos foi fundada em 1991 na Cidade de Teresópolis, região serrana do Estado do Rio de Janeiro, e logo se expandiu para outras cidades da região, hoje temos lojas e pontos de atendimento personalizado nas cidades de: Teresópolis, Nova Friburgo e Cabo Frio.
                    <br /><br />
                    A São José Ortopédicos também conta com uma Oficina Ortopédica totalmente equipada, capacitada e credenciada junto aos órgãos governamentais competentes, para produção de qualquer aparelho ortopédico (órteses e próteses), estrategicamente instalada em Teresópolis.
                </p>
            </div>
            <div className="display--flex flex--center">
                <p className="margin--right">
                    O proprietário e responsável técnico é Ortesista-Protesista Joaquim Cunha é membro da Associação Brasileira de Ortopedia Técnica (ABOTEC Mat.064), membro da International Society for Prosthetics & Orthotics (ISPO Mat.3301), atualmente também atua como Presidente do Capítulo Brasil da International Society for Prosthetics and Orthotics (ISPO-Brasil).
                    <br /><br />
                    Além da produção de aparelhos ortopédicos, confeccionados sob medida, a São José Ortopédicos também comercializa material Médico-hospitalar em geral, como: Móveis hospitalares, Instrumental cirúrgico, Monitores de glicemia, Inaladores, Cadeiras de rodas, Muletas, Meias medicinais compressivas, Malhas para queimados e Linfedemas, dá assistência técnica em aparelhos de inalação, cadeiras de rodas motorizadas, etc.
                </p>
                <img className="quem-somos__img" src={proteses} alt="Próteses"/>
            </div>
        </>
    );
}

export default QuemSomos;