import React from "react";
import imgAbaixoJoelho1 from "../../../assets/produtos_proteses_abaixo_joelho_1.jpg";
import imgAbaixoJoelho2 from "../../../assets/produtos_proteses_abaixo_joelho_2.jpg";
import imgAbaixoJoelho3 from "../../../assets/produtos_proteses_abaixo_joelho_3.jpg";
import imgAbaixoJoelho4 from "../../../assets/produtos_proteses_abaixo_joelho_4.jpg";
import imgAbaixoJoelho5 from "../../../assets/produtos_proteses_abaixo_joelho_5.jpg";
import imgAbaixoJoelho6 from "../../../assets/produtos_proteses_abaixo_joelho_6.jpg";
import imgAbaixoJoelho7 from "../../../assets/produtos_proteses_abaixo_joelho_7.jpg";
import imgAbaixoJoelho8 from "../../../assets/produtos_proteses_abaixo_joelho_8.jpg";
import imgAbaixoJoelho9 from "../../../assets/produtos_proteses_abaixo_joelho_9.jpg";
import imgAbaixoJoelho10 from "../../../assets/produtos_proteses_abaixo_joelho_10.jpg";
import imgAbaixoJoelho11 from "../../../assets/produtos_proteses_abaixo_joelho_11.jpg";
import imgAbaixoJoelho12 from "../../../assets/produtos_proteses_abaixo_joelho_12.jpg";
import imgAbaixoJoelho13 from "../../../assets/produtos_proteses_abaixo_joelho_13.jpg";
import imgAbaixoJoelho14 from "../../../assets/produtos_proteses_abaixo_joelho_14.jpg";
import imgAbaixoJoelho15 from "../../../assets/produtos_proteses_abaixo_joelho_15.jpg";
import imgAbaixoJoelho16 from "../../../assets/produtos_proteses_abaixo_joelho_16.jpg";

function AbaixoDoJoelho() {
    return (
        <>
            <h2>Amputações abaixo do joelho</h2>
            <h3>Esclarecimentos e conselhos úteis, e Técnicas de Enfaixamento</h3>
            <p>
                Quase todos os amputados se sentem um tanto deprimidos imediatamente após a cirurgia, com exceção daqueles que sofreram dor intensa por determinado período antes da amputação. Esta depressão, normalmente, é logo substituída pelo desejo de se retornar a uma vida ativa.<br /><br />
                Os curativos permanece, em média, por um período de 15 dias aproximadamente, prazo em que normalmente acontece a cicatrização. Logo após a total cicatrização, ataduras elásticas são usadas para ajudar a circulação e evitar, ou reduzir edemas (inchaço). As ataduras elásticas são removidas e recolocadas ao longo do dia (instruções para aplicação de ataduras elásticas são dadas na próxima seção).<br /><br />
                Além dos curativos específicos utilizados pela equipe médica, exercícios orientados por profissionais especializados, são extremamente importantes para prevenir as contraturas musculares pois, quando isto acontece, dificulta, ou mesmo, impede o uso eficiente da prótese.<br /><br />
                Alguns “Conselhos do que NÃO FAZER” ajudarão a prevenir contraturas musculares, são mostrados abaixo.<br /><br />
                É muito importante que os exercícios prescritos pelo profissional habilitado sejam cumpridos regularmente e as posições mostradas no quadro sejam evitadas a fim de ser obtida uma performance melhor com o uso das próteses.
            </p>
            <div className="images">
                <img src={imgAbaixoJoelho1} alt="Abaixo do joelho" className="image image--large"/>
            </div>
            <p>
                No geral, quanto mais cedo é colocada a prótese, melhor para o amputado. Um dos problemas mais difíceis no amputado é o EDEMA ou inchaço do coto, devido ao acúmulo de fluídos. O EDEMA estará presente, até certo ponto, em todos os casos e isto faz com que a colocação da prótese seja difícil, mas certas medidas podem ser tomadas para reduzir a quantidade de EDEMA. Depois que ocorre a total cicatrização do coto, ataduras elásticas são utilizadas para impedir o desenvolvimento e reduzir o EDEMA. Técnicas de Enfaixamento são apresentadas a seguir.<br /><br />
                Para cotos mais curto utiliza-se ataduras elásticas de 10cm de largura com 1,3m de comprimento e para cotos mais longos utiliza-se ataduras elásticas de 10cm de largura e 2,6m de comprimento. Durante a utilização, as ataduras devem ser esticadas cerca de metade do limite de sua elasticidade, e a maior tensão deve ser em torno do final do coto, decrescendo ao aproximar-se do joelho.<br /><br />
                O coto deve ser mantido atado o tempo todo, mas o enfaixamento deve ser refeito a cada três ou quatro horas. O enfaixamento nunca deve ser mantido por mais de 12 horas sem renova-lo. Se dores latejantes ou sensação de dormência ocorrerem, o enfaixamento deve ser removido e recolocado. Normalmente, o latejamento ou sensação de dormência no coto estão relacionados a um enfaixamento com muita pressão.O EDEMA volta a ocorrer rapidamente quando o coto é deixado sem enfaixamento, então é muito importante recoloca-lo sem demora. À noite pode-se fazer um enfaixamento levemente com menos pressão, mas nunca se deve dormir sem a faixa, se esta estiver saindo durante a noite é sinal de que a pressão está sendo muito pouca.<br /><br />
                Meias compressivas especiais apropriadas para uso, ao invés de ataduras elásticas, embora não consideradas por algumas, podem ser utilizadas por aqueles pacientes que não consigam utilizar as ataduras elásticas.<br /><br />
                Se atadura elástica ou meia compressiva é usada, esta deve ser removida pelo menos 3 vezes ao dia e o coto deve ser massageado vigorosamente, por 10 a 15 minutos. A atadura ou meia devem ser recolocadas imediatamente após a massagem.
            </p>
            <div className="images">
                <img src={imgAbaixoJoelho2} alt="Abaixo do joelho" className="image image--large"/>
            </div>
            <h3>Próteses convencionais de pé</h3>
            <p>
                Tipos de amputação de pé:
            </p>
            <div className="images">
                <img src={imgAbaixoJoelho3} alt="Abaixo do joelho" className="image"/>
            </div>
            <p>
                Devido ao comprimento do coto após uma amputação parcial de pé, o uso de componentes pré-fabricados para este nível de amputação é limitado. Por este motivo, as próteses geralmente são fabricadas artesanalmente em material plástico ou resina. O posicionamento correto do coto dentro da prótese praticamente determina o seu alinhamento estático.
            </p>
            <div className="images">
                <img src={imgAbaixoJoelho4} alt="Abaixo do joelho" className="image"/>
            </div>
            <p>
                Amputações parciais de LISFRANC ou CHOPART, muitas vezes podem podem ser protetisados com uma complementação interna no calçado, dependendo da forma e das condições do coto.<br /><br />
                Cotos de CHOPART mal formados ou mal posicionados, assim como amputações parciais de PIROGOFF ou SYME, necessitam de um encaixe mais abrangente, quando não é possível se obter o apoio na base do coto, pode ser necessário um apoio no joelho.
            </p>
            <div className="images">
                <img src={imgAbaixoJoelho5} alt="Abaixo do joelho" className="image"/>
                <img src={imgAbaixoJoelho6} alt="Abaixo do joelho" className="image"/>
                <img src={imgAbaixoJoelho7} alt="Abaixo do joelho" className="image"/>
            </div>
            <h3>Próteses convencionais</h3>
            <p>
                Para a protetização de amputações abaixo do joelho, ainda muitas pessoas utilizam as próteses convencionais com contato total e suspensão supracondiliana.<br /><br />
                Próteses com hastes laterais e coxal em couro somente são indicadas em casos especiais (cotos curtos, lesões ligamentares), eventualmente com apoio na tuberosidade isquiática.<br /><br />
                O encaixe e o corpo da prótese são confeccionados em resina com malha de fibra de vidro, ou de carbono e utiliza-se um encaixe interno com forração macia.<br /><br />
                Encaixe externo, tornozelo e pé determinam o aspecto final da prótese.
            </p>
            <div className="images">
                <img src={imgAbaixoJoelho8} alt="Abaixo do joelho" className="image"/>
                <img src={imgAbaixoJoelho9} alt="Abaixo do joelho" className="image"/>
                <img src={imgAbaixoJoelho10} alt="Abaixo do joelho" className="image"/>
            </div>
            <h3>Próteses de banho</h3>
            <p>
                A prótese é totalmente, assim como a prótese convencional, e confeccionada em resina.<br /><br />
                O encaixe pode ter uma parte interna em polifórmio e, normalmente, possui uma suspensão supracondiliana.<br /><br />
                Os orifícios laterais permitem a entrada de água, reduzindo a força ascensional (flutuação).<br /><br />
                Para este tipo de prótese, só podem ser utilizados pés não-articulados.
            </p>
            <div className="images">
                <img src={imgAbaixoJoelho11} alt="Abaixo do joelho" className="image"/>
                <img src={imgAbaixoJoelho12} alt="Abaixo do joelho" className="image"/>
            </div>
            <h3>Próteses modulares</h3>
            <p>
                Este tipo de prótese é indicado para a protetização abaixo do joelho, para todos os tipos de amputações transtibiais, curtas ou longas.<br /><br />
                A conexão do encaixe com o pé é feita através de vários tipos de adaptadores.<br /><br />
                O revestimento cosmético em espuma recebe um acabamento individual de acordo com a perna contralateral, dando um aspecto mais natural à prótese.<br /><br />
                A forma se suspensão da prótese, ou seja, o que assegura que a prótese não vá cair durante a deambulação, pode ser por pressão supra condiliana, com a utilização de liner com pino de fixação distal ou por vácuo com liner (sem pino), joelheira e válvula de expulsão de ar.
            </p>
            <div className="images">
                <img src={imgAbaixoJoelho13} alt="Abaixo do joelho" className="image"/>
                <img src={imgAbaixoJoelho14} alt="Abaixo do joelho" className="image"/>
                <img src={imgAbaixoJoelho16} alt="Abaixo do joelho" className="image"/>
                <img src={imgAbaixoJoelho15} alt="Abaixo do joelho" className="image"/>
            </div>
        </>
    )
}

export default AbaixoDoJoelho;