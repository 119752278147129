import React from "react";

function MembrosSuperiores() {
    return (
        <>
            <h2>Membros superiores</h2>
            <h3>Prev-tala para punho</h3>
            <p>
                Indicações:<br />
                Prevenção da tendinite durante o trabalho e outras afecções da região.
            </p>
            <p>
                Características:<br />
                Confeccionada em lona dupla resistente;<br />
                Ajuste em velcro com passadores;<br />
                Reforço interno de plástico PVC rígido;<br />
                Lavável.
            </p>
            <h3>Tala para punho longo com dedos livres</h3>
            <p>
                Indicações:<br />
                Tenossinovites do punho, artroses em caso de periostite, lesões ligamentares, correção da posição viciosa na artríte reumatoide, tendinite em estagio avançado e outras afcções da região.
            </p>
            <p>
                Características:<br />
                Confeccionada em lona dupla resistente;<br />
                Ajuste em velcro com passadores;<br />
                Reforço interno de plástico PVC rígido;<br />
                Lavável.
            </p>
            <h3>Tala para punho com dedos livres</h3>
            <p>
                Indicações:<br />
                Tenossinovites do punho, artroses, lesões ligamentares, correção da posição viciosa na artríte reumatóide e outras afecções de origem traumáticas e neurológicas. Prevenção da recidiva no retorno às atividades.
            </p>
            <p>
                Características:<br />
                Confeccionada em lona dupla resistente;<br />
                Ajuste em velcro com passadores;<br />
                Reforço interno de plástico PVC rígido;<br />
                Lavável.
            </p>
            <h3>Tala em lona para punho e polegar</h3>
            <p>
                Indicações:<br />
                Fraturas, tenossinovite de De Quervain, entorses, contusões, artroses e redução articular da artríte reumatóide do polegar, imobilização isolada do polegar.
            </p>
            <p>
                Características:<br />
                Confeccionadas em lona dupla resistente;<br />
                Fecho em velcro com passadores;<br />
                Reforço interno de plástico PVC rígido.
            </p>
            <h3>Munhequeira</h3>
            <p>
                Indicações:<br />
                Afecções leves do punho na prática esportiva, prevenção da recidiva.
            </p>
            <p>
                Características:<br />
                Confeccionada em elástico macio e resistente;<br />
                Ajuste em velcro;<br />
                Lavável.
            </p>
            <h3>Tala em PVC para punho, mão e dedos</h3>
            <p>
                Indicações:<br />
                Para lesões traumáticas, paralisia dos nervos periféricos, sequelas de ACV, deformidade de artríte reumatóide, síndrome de túnel do carpo e outras afecções.
            </p>
            <p>
                Características:<br />
                Confeccionada em PVC moldado e fechos aderentes.
            </p>
            <h3>Tala em PVC para punho e polegar</h3>
            <p>
                Indicações:<br />
                Para fraturas, tenossinovite de De Quervin, entorses, contusões, artroses e reeducação articular na artríte reumatóide do polegar. Imobilização isolada do polegar.
            </p>
            <p>
                Características:<br />
                Confeccionada em PVC moldado com tirantes aderente e forro removível de espuma.
            </p>
            <h3>Tala em PVC para polegar</h3>
            <p>
                Indicações:<br />
                Para fraturas, entorses, contusões, artroses e reeducação articular na artríte reumatóide do polegar. Imobilização isolada do polegar.
            </p>
            <p>
                Características:<br />
                Confeccionada em PVC moldado com tirantes aderentes.
            </p>
            <h3>Tipóia simples</h3>
            <p>
                Indicações:<br />
                Sustentação do membro superior em caso menos graves de instabilidade da artríte reumatóide, sequelas de luxação e sequelas de AVC. Apoio e sustentação do aparelho gessado. Pós- operatório.
            </p>
            <p>
                Características:<br />
                Confeccionada em brim leve de algodão resistente e macio;<br />
                Alça regulável com fivela plástica;<br />
                Lavável.
            </p>
            <h3>Tipóia imobilizadora estofada Velpeau</h3>
            <p>
                Indicações:<br />
                Imobilização do membro superior.<br />
                Contusões, luxações recidivantes e instabilidades graves do ombro, traumáticas ou de artríte reumatóide.<br />
                Fraturas do colo do úmero e do cotovelo, bursites, períartrites, sequelas de AVC e outras afecções do membro superior.
            </p>
            <p>
                Características:<br />
                Confeccionada em brim de algodão duplo e estofado, para maior firmeza e conforto;<br />
                Fivelas plásticas para ajustar;<br />
                Fecho em velcro;<br />
                Lavável.
            </p>
            <h3>Cinta tênis Elbow-longa</h3>
            <p>
                Indicações:<br />
                Auxilia e previne problemas de tendinite, bursite, artrite, artrose e outras afecções do cotovelo por motivos intensos. Prevenção de recidiva na prática esportiva, imobiliza parcialmente a articulação.
            </p>
            <p>
                Características:<br />
                Confeccionada em neoprene revestido.
            </p>
            <h3>Cinta tênis Elbow-curta</h3>
            <p>
                Indicações:<br />
                Para diminuir o stress sobre os músculos e tendões, causados por movimentos intensos.
            </p>
            <p>
                Características:<br />
                Confeccionada em neoprene revestido.
            </p>
            <h3>Suporte para ombros</h3>
            <p>
                Indicações:<br />
                Alivia dores no ombro originadas de bursite, luxações, artrite, reumatismo localizado e ouras afecções.
            </p>
            <p>
                Características:<br />
                Confeccionada em neoprene revestido.
            </p>
        </>
    )
}

export default MembrosSuperiores;