import React from "react";
import imgAnteBraco1 from "../../../assets/produtos_proteses_antebraco_1.jpg";
import imgAnteBraco2 from "../../../assets/produtos_proteses_antebraco_2.jpg";
import imgAnteBraco3 from "../../../assets/produtos_proteses_antebraco_3.jpg";
import imgAnteBraco4 from "../../../assets/produtos_proteses_antebraco_4.jpg";
import imgAnteBraco5 from "../../../assets/produtos_proteses_antebraco_5.jpg";
import imgAnteBraco6 from "../../../assets/produtos_proteses_antebraco_6.jpg";
import imgAnteBraco7 from "../../../assets/produtos_proteses_antebraco_7.jpg";
import imgAnteBraco8 from "../../../assets/produtos_proteses_antebraco_8.jpg";
import imgAnteBraco9 from "../../../assets/produtos_proteses_antebraco_9.jpg";
import imgAnteBraco10 from "../../../assets/produtos_proteses_antebraco_10.jpg";
import imgAnteBraco11 from "../../../assets/produtos_proteses_antebraco_11.jpg";
import imgAnteBraco12 from "../../../assets/produtos_proteses_antebraco_12.jpg";
import imgAnteBraco13 from "../../../assets/produtos_proteses_antebraco_13.jpg";


function Antebraco() {
    return (
        <>
            <h2>Amputações de antebraço</h2>
            <h3>Prótese ativa para amputação de ante-braço</h3>
            <p>
                Este tipo de prótese funcional, também chamado de braço ativo, possibilita a protetisação de amputações de antebraço de diferentes níveis, especialmente quando não existem meios para a colocação de uma prótese mioelétrica.<br />
                Em comparação a uma prótese mioelétrica ela tem a vantagem de pesar menos e ser independente de uma fonte de energia.<br />
                Por outro lado, o emprego de um tirante de tração representa uma limitação de movimentos e menor conforto de uso.<br />
                A fixação da prótese é feita através de um encaixe de contato envolvendo o cotovelo. Para cotos extremamente curtos torna-se necessário um auxílio de fixação.<br />
                Utiliza-se uma mão ou um gancho com peça terminal. A fixação ao encaixe externo pode ser feita através de diferentes tipos de punhos.
            </p>
            <div className="images">
                <img src={imgAnteBraco1} alt="Antebraço" className="image"/>
                <img src={imgAnteBraco2} alt="AnteBraço" className="image"/>
                <img src={imgAnteBraco3} alt="AnteBraço" className="image"/>
                <img src={imgAnteBraco4} alt="AnteBraço" className="image"/>
            </div>
            <h3>Próteses estéticas</h3>
            <p>
                A prótese estética é indicada para pacientes que dispensam ou não se adaptam a uma prótese funcional.<br />
                As características específicas desta prótese são o peso reduzido e o manuseio simples, mas ela possui uma função passiva muito restrita.<br />
                A fixação da prótese é feita através de um encaixe de contato envolvendo o cotovelo. Utiliza-se uma mão cosmética ou passiva como peça terminal.<br />
                A sua fixação ao encaixe externo pode ser feita através de diferentes tipos de punhos. Uma luva cosmética reveste a mão. Sua forma, cor e estrutura proporcionam um aspecto natural a prótese.
            </p>
            <div className="images">
                <img src={imgAnteBraco5} alt="Antebraço" className="image"/>
                <img src={imgAnteBraco6} alt="AnteBraço" className="image"/>
            </div>
            <h3>Próteses Mioelétricas e Biônicas, para amputação de antebraço</h3>
            <p>
                O principal pré-requisito para a protetisação com um sistema mioelétrico e/ou biônico para antebraço são potenciais musculares suficientemente fortes para o controle da prótese.<br />
                Eles são captados por meio de eletrodos, e amplificados e transmitidos como sinais ao processador.<br />
                A foto mostra vários tipos de próteses (a-d) com diferentes princípios de construção para os movimentos de prono-supinação.<br />
                O tipo mais simples (a) somente permite uma rotação ou posicionamento da mão de forma passiva.<br />
                A prótese com prono-supinação ativa (b) possui uma unidade de giro entre a mão e o encaixe.<br />
                A terceira possibilidade é a prono-supinação elétrica (c), controlava através de uma ligeira rotação do coto, acionando assim um micro-interruptor.<br />
                A prótese mioelétrica de 4 canais (d) possibilita um controle independente da mão e do movimento de prono-supinação, através de sinais mioelétricos diferenciados (2 funções por eletrodo).<br />
                A fonte de energia é um acumulador (bateria) recarregável de 6 Volts.<br />
                As próteses para antebraço possuem um encaixe interno de contato envolvendo o cotovelo, e um encaixe externo laminado.<br />
                A tecnologia Biônica utiliza, basicamente, os mesmos princípios de ativação e captação de pulsos elétricos dos músculos, porém, com eletrodos de captação mais abrangentes e com maior sensibilidade.<br />
                As mãos protésicas utilizadas nesse sistema contam com uma gama muito maior de movimentos dos dedos e possibilitam distintos tipos de pegadas pré-programadas.
            </p>
            <div className="images">
                <img src={imgAnteBraco7} alt="Antebraço" className="image"/>
                <img src={imgAnteBraco8} alt="AnteBraço" className="image"/>
                <img src={imgAnteBraco9} alt="AnteBraço" className="image"/>
                <img src={imgAnteBraco10} alt="AnteBraço" className="image"/>
                <img src={imgAnteBraco11} alt="AnteBraço" className="image"/>
                <img src={imgAnteBraco12} alt="AnteBraço" className="image"/>
                <img src={imgAnteBraco13} alt="AnteBraço" className="image"/>
            </div>
        </>
    )
}

export default Antebraco;