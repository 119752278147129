import React from "react";
import imgQuadril1 from "../../../assets/produtos_proteses_quadril_1.jpg"
import imgQuadril2 from "../../../assets/produtos_proteses_quadril_2.jpg"
import imgQuadril3 from "../../../assets/produtos_proteses_quadril_3.jpg"
import imgQuadril4 from "../../../assets/produtos_proteses_quadril_4.jpg"

function Quadril() {
    return (
        <>
            <h2>Próteses para quadril</h2>
            <h3>Prótese Convencional</h3>
            <p>
                Indicações:<br />
                Este tipo de prótese é utilizado quando situações adversas (determinadas atividades do paciente, condições geográficas) inviabilizam o uso de uma prótese modular, tecnicamente mais avançada e leve, portanto mais indicada para este nível de amputação.
            </p>
            <p>
                Composição:<br />
                A prótese é composta pelo encaixe, a barra posterior de apoio, a articulação de joelho com panturrilha, o tornozelo e o pé. O encaixe em resina tem a forma de um cesto, com uma abertura na parte anterior.<br />
                Ele está ligado à prótese através de um eixo (articulação de quadril), além de possuir uma barra de sustentação na parte posterior.
            </p>
            <div className="images">
                <img className="image" src={imgQuadril1} alt="Quadril"/>
            </div>
            <h3>Prótese Modular</h3>
            <p>
                Indicações:<br />
                Este tipo de prótese é indicado para a protetização após amputações intertrocantéricas, desarticulações de quadril e hemipelvectomias.
            </p>
            <p>
                Composição:<br />
                O encaixe em forma de cesto possui uma base de metal previamente incorporada à resina, através da qual se faz a conexão com os demais componentes.<br />
                Em caso de hemipelvectomia, pode tornar-se necessário o uso de um cesto englobando parte da região torácica.<br />
                O revestimento cosmético em espuma, confeccionado individualmente, dá um aspecto mais natural à prótese.
            </p>
            <div className="images">
                <img className="image" src={imgQuadril2} alt="Quadril"/>
                <img className="image" src={imgQuadril3} alt="Quadril"/>
                <img className="image" src={imgQuadril4} alt="Quadril"/>
            </div>
        </>
    )
}

export default Quadril;