import React from "react";

function Tronco() {
    return (
        <>
            <h2>Tronco e coluna vertebral</h2>
            <h3>Colares, espaldeiras, faixas, coletes, imobilizadores e colar cervical noturno</h3>
            <p>
                Indicações:<br />
                Torcicolos, traumatismo, artroses, artrites, estabilização da postura durante o sono.
            </p>
            <p>
                Características:<br />
                Espuma macia de alta densidade; Reforço de apoio interno;<br />
                Fecho em velcro.
            </p>
            <h3>Colar Cervical Tipo Thomas</h3>
            <p>
                Indicações:<br />
                Torcicolos, traumatismo, artroses, artrítes e afecções da coluna cervical.
            </p>
            <p>
                Características:<br />
                Polietileno de alta densidade; Bordas acolchoadas;<br />
                Altura ajustável;<br />
                Fecho em velcro;<br />
                Lavável.
            </p>
            <h3>Colar com apoio Mentoniano</h3>
            <p>
                Indicações:<br />
                Torcicolos, traumatismo, reumatóide, artroses e outras afcções da coluna cervical.
            </p>
            <p>
                Características:<br />
                Polietileno de alta densidade;<br />
                Apoio metoniano;<br />
                Bordas acolchoadas;<br />
                Altura ajustável;<br />
                Fecho em velcro;<br />
                Lavável.
            </p>
        </>
    )
}

export default Tronco;