import Ombro from "./ombro";
import Quadril from "./quadril";
import Pes from "./pes";
import MembrosSuperiores from "./membros_superiores";
import MembrosInferiores from "./membros_inferiores";
import Tronco from "./tronco";
import Diabeticos from "./diabeticos";
import Home from "./home";

const module = {
    Home,
    Ombro,
    Quadril,
    Pes,
    MembrosSuperiores,
    MembrosInferiores,
    Tronco,
    Diabeticos
};

export default module;