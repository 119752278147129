import React from "react";
import selo30anos from "../assets/selo-30-anos.jpeg";
import './header.css';
import {NavLink} from "react-router-dom";

function Header() {
  return (
    <header className="header">
      <img className="header__selo" src={selo30anos} alt="São José 30 anos"/>
      <div className="container">
        <h1 className="header__text">
          Próteses e órteses sob medida para liberar seu pleno potencial
        </h1>
        <nav className="header-nav">
          <ul className="header-nav__list">
            <li className="header-nav__list-item">
              <NavLink activeClassName="active" className="header-nav__list-item-link" to="/">Início</NavLink>
            </li>
            <li className="header-nav__list-item">
                <NavLink activeClassName="active" className="header-nav__list-item-link" to="/quem-somos">Quem somos</NavLink>
            </li>
            <li className="header-nav__list-item">
                <NavLink activeClassName="active" className="header-nav__list-item-link" to="/produtos">Produtos</NavLink>
            </li>
            <li className="header-nav__list-item">
                <NavLink activeClassName="active" className="header-nav__list-item-link" to="/servicos">Serviços</NavLink>
            </li>
            <li className="header-nav__list-item">
              <NavLink activeClassName="active" className="header-nav__list-item-link" to="/contato">Contato</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;