import imgAbaixoJoelho from "../assets/produtos_proteses_abaixo_joelho.png";
import imgAcimaJoelho from "../assets/produtos_proteses_acima_joelho.png";
import imgAntebraco from "../assets/produtos_proteses_antebraco.png";
import imgBraco from "../assets/produtos_proteses_braco.png";
import imgMaosDedos from "../assets/produtos_proteses_maos_dedos.png";
import imgPunho from "../assets/produtos_proteses_punho.png";
import imgCotovelo from "../assets/produtos_proteses_cotovelo.png";
import imgOmbro from "../assets/produtos_proteses_ombro.png";
import imgJoelho from "../assets/produtos_proteses_joelho.png";
import imgQuadril from "../assets/produtos_proteses_quadril.png";
import imgMama from "../assets/produtos_proteses_mama.png";
import imgPernas from "../assets/produtos_orteses_pernas.png";
import imgPes from "../assets/produtos_orteses_pes.png";
import imgTronco from "../assets/produtos_orteses_tronco.png";
import imgDiabeticos from "../assets/produtos_orteses_diabeticos.png";
import imgCadeirasRodas from "../assets/produtos_medico_hospitalar_cadeiras_rodas.png";
import imgHomecare from "../assets/produtos_medico_hospitalar_home_care.png";
import imgInfralux from "../assets/produtos_medico_hospitalar_infralux.png";
import imgScooter from "../assets/produtos_medico_hospitalar_scooter.png";
import imgAndadores from "../assets/produtos_medico_hospitalar_andadores.png";
import imgEletroterapia1 from "../assets/eletroterapia_1.jpg";
import imgEletroterapia2 from "../assets/eletroterapia_2.jpg";
import imgEletroterapia3 from "../assets/eletroterapia_3.jpg";
import imgEletroterapia4 from "../assets/eletroterapia_4.jpg";
import imgEletroterapia5 from "../assets/eletroterapia_5.jpg";
import imgEletroterapia6 from "../assets/eletroterapia_6.jpg";
import imgEletroterapia7 from "../assets/eletroterapia_7.jpg";
import imgAvaliacao1 from "../assets/avaliacao_1.jpg";
import imgAvaliacao2 from "../assets/avaliacao_2.jpg";
import imgAvaliacao3 from "../assets/avaliacao_3.jpg";
import imgAvaliacao4 from "../assets/avaliacao_4.jpg";
import imgAvaliacao5 from "../assets/avaliacao_5.jpg";
import imgAvaliacao6 from "../assets/avaliacao_6.jpg";
import imgAvaliacao7 from "../assets/avaliacao_7.jpg";
import imgAvaliacao8 from "../assets/avaliacao_8.jpg";
import imgBolas1 from "../assets/bolas_1.jpg";
import imgBolas2 from "../assets/bolas_2.jpg";
import imgBolas3 from "../assets/bolas_3.jpg";
import imgBolas4 from "../assets/bolas_4.jpg";
import imgBolas5 from "../assets/bolas_5.jpg";
import imgBolas6 from "../assets/bolas_6.jpg";
import imgPilates1 from "../assets/pilates_1.jpg";
import imgPilates2 from "../assets/pilates_2.jpg";
import imgPilates3 from "../assets/pilates_3.jpg";
import imgPilates4 from "../assets/pilates_4.jpg";
import imgPilates5 from "../assets/pilates_5.jpg";
import imgPilates6 from "../assets/pilates_6.jpg";
import imgPilates7 from "../assets/pilates_7.jpg";
import imgMecanoterapia1 from "../assets/mecanoterapia_1.jpg";
import imgMecanoterapia2 from "../assets/mecanoterapia_2.jpg";
import imgMecanoterapia3 from "../assets/mecanoterapia_3.jpg";
import imgMecanoterapia4 from "../assets/mecanoterapia_4.jpg";
import imgMecanoterapia5 from "../assets/mecanoterapia_5.jpg";
import imgMecanoterapia6 from "../assets/mecanoterapia_6.jpg";
import imgMecanoterapia7 from "../assets/mecanoterapia_7.jpg";
import imgMecanoterapia8 from "../assets/mecanoterapia_8.jpg";
import imgMecanoterapia9 from "../assets/mecanoterapia_9.jpg";
import imgMecanoterapia10 from "../assets/mecanoterapia_10.jpg";
import imgMecanoterapia11 from "../assets/mecanoterapia_11.jpg";
import imgMecanoterapia12 from "../assets/mecanoterapia_12.jpg";
import imgMecanoterapia13 from "../assets/mecanoterapia_13.jpg";
import imgMesas1 from "../assets/mesas_1.jpg";
import imgMesas2 from "../assets/mesas_2.jpg";
import imgMesas3 from "../assets/mesas_3.jpg";
import imgMesas4 from "../assets/mesas_4.jpg";
import imgMesas5 from "../assets/mesas_5.png";
import imgMesas6 from "../assets/mesas_6.jpg";
import imgMesas7 from "../assets/mesas_7.png";
import imgMesas8 from "../assets/mesas_8.jpg";

const database = {
    proteses: {
        title: 'Próteses',
        description: 'Próteses Ortopédicas, são aparelhos e/ou equipamentos que venham substituir partes do corpo humano como, por exemplo, Pernas Mecânicas, Braços Mecânicos, etc… (a nomenclatura correta seria: Prótese de Membro Inferior e Prótese de Membro Superior).',
        items: [
            {
                title: 'Amputação',
                items: [
                    {
                        title: 'Abaixo do joelho',
                        icon: imgAbaixoJoelho,
                        path: '/produtos/proteses/abaixo-joelho',
                        iconLegend: 'Ícone abaixo do joelho'
                    },
                    {
                        title: 'Acima do joelho',
                        icon: imgAcimaJoelho,
                        path: '/produtos/proteses/acima-joelho',
                        iconLegend: 'Ícone acima do joelho'
                    },
                    {
                        title: 'Antebraço',
                        icon: imgAntebraco,
                        path: '/produtos/proteses/antebraco',
                        iconLegend: 'Ícone Antebraço'
                    },
                    {
                        title: 'Braço',
                        icon: imgBraco,
                        path: '/produtos/proteses/braco',
                        iconLegend: 'Ícone braço'
                    },
                    {
                        title: 'Mãos e dedos',
                        icon: imgMaosDedos,
                        path: '/produtos/proteses/maos-e-dedos',
                        iconLegend: 'Ícone mãos e dedos'
                    },
                ]
            },
            {
                title: 'Desarticulação',
                items: [
                    {
                        title: 'Punho',
                        icon: imgPunho,
                        path: '/produtos/proteses/punho',
                        iconLegend: 'Ícone punho'
                    },
                    {
                        title: 'Cotovelo',
                        icon: imgCotovelo,
                        path: '/produtos/proteses/cotovelo',
                        iconLegend: 'Ícone cotovelo'
                    },
                    {
                        title: 'Ombro',
                        icon: imgOmbro,
                        path: '/produtos/proteses/ombro',
                        iconLegend: 'Ícone ombro'
                    }
                ]
            },
            {
                title: 'Próteses',
                items: [
                    {
                        title: 'Joelho',
                        icon: imgJoelho,
                        path: '/produtos/proteses/joelho',
                        iconLegend: 'Ícone joelho'
                    },
                    {
                        title: 'Quadril',
                        icon: imgQuadril,
                        path: '/produtos/proteses/quadril',
                        iconLegend: 'Ícone quadril'
                    },
                    {
                        title: 'Mama',
                        icon: imgMama,
                        path: '/produtos/proteses/mama',
                        iconLegend: 'Ícone mama'
                    }
                ]
            }
        ]
    },
    orteses: {
        title: 'Órteses',
        description: 'Órteses Ortopédicas são aparelhos e/ou equipamentos que venham a sustentar ou corrigir alguma parte do corpo humano como, por exemplo, Palmilhas Ortopédicas, Coletes, Tutores, Talas, etc…',
        items: [
            {
                title: 'Ombro',
                icon: imgOmbro,
                path: '/produtos/orteses/ombro',
                iconLegend: 'Ícone ombro'
            },
            {
                title: 'Quadril',
                icon: imgQuadril,
                path: '/produtos/orteses/quadril',
                iconLegend: 'Ícone quadril'
            },
            {
                title: 'Pés',
                icon: imgPes,
                path: '/produtos/orteses/pes',
                iconLegend: 'Ícone pés'
            },
            {
                title: 'Membros superiores',
                icon: imgBraco,
                path: '/produtos/orteses/membros-superiores',
                iconLegend: 'Ícone membros superiores'
            },
            {
                title: 'Membros inferiores',
                icon: imgPernas,
                path: '/produtos/orteses/membros-inferiores',
                iconLegend: 'Ícone membros inferiores'
            },
            {
                title: 'Tronco e coluna vertebral',
                icon: imgTronco,
                path: '/produtos/orteses/tronco',
                iconLegend: 'Ícone tronco'
            },
            {
                title: 'Para diabéticos',
                icon: imgDiabeticos,
                path: '/produtos/orteses/diabeticos',
                iconLegend: 'Ícone diabéticos'
            }
        ]
    },
    medico_hospitalar: {
        title: 'Médico-hospitalar',
        description: null,
        items: [
            {
                title: 'Cadeiras de rodas',
                icon: imgCadeirasRodas,
                path: '/produtos/medico-hospitalar/cadeiras',
                iconLegend: 'Ícone cadeiras de rodas'
            },
            {
                title: 'Home-care',
                icon: imgHomecare,
                path: '/produtos/medico-hospitalar/home-care',
                iconLegend: 'Ícone home-care'
            },
            {
                title: 'Infralux',
                icon: imgInfralux,
                path: '/produtos/medico-hospitalar/infralux',
                iconLegend: 'Ícone Infralux'
            },
            {
                title: 'Scooter',
                icon: imgScooter,
                path: '/produtos/medico-hospitalar/scooter',
                iconLegend: 'Ícone scooter'
            },
            {
                title: 'Andadores',
                icon: imgAndadores,
                path: '/produtos/medico-hospitalar/andadores',
                iconLegend: 'Ícone andadore'
            },
        ]
    },
    fisioterapia: {
        title: 'Fisioterapia',
        items: [
            {
                title: 'Eletroterapia',
                description: 'Ultrassom, Tens + Fes, Ondas Curtas, Eletrodos, Laser, Tens, Gel e etc',
                images: [
                    {
                        url: imgEletroterapia1,
                        legend: 'Eletroterapia'
                    },
                    {
                        url: imgEletroterapia2,
                        legend: 'Eletroterapia'
                    },
                    {
                        url: imgEletroterapia3,
                        legend: 'Eletroterapia'
                    },
                    {
                        url: imgEletroterapia4,
                        legend: 'Eletroterapia'
                    },
                    {
                        url: imgEletroterapia5,
                        legend: 'Eletroterapia'
                    },
                    {
                        url: imgEletroterapia6,
                        legend: 'Eletroterapia'
                    },
                    {
                        url: imgEletroterapia7,
                        legend: 'Eletroterapia'
                    }
                ]
            },
            {
                title: 'Avaliação',
                description: 'Dinamômetros, Adipômetros, Goniômetros, Martelos de reflexo e etc',
                images: [
                    {
                        url: imgAvaliacao1,
                        legend: 'Avaliação'
                    },
                    {
                        url: imgAvaliacao2,
                        legend: 'Avaliação'
                    },
                    {
                        url: imgAvaliacao3,
                        legend: 'Avaliação'
                    },
                    {
                        url: imgAvaliacao4,
                        legend: 'Avaliação'
                    },
                    {
                        url: imgAvaliacao5,
                        legend: 'Avaliação'
                    },
                    {
                        url: imgAvaliacao6,
                        legend: 'Avaliação'
                    },
                    {
                        url: imgAvaliacao7,
                        legend: 'Avaliação'
                    },
                    {
                        url: imgAvaliacao8,
                        legend: 'Avaliação'
                    }
                ]
            },
            {
                title: 'Bolas',
                description: 'Gynastic Ball, Orange Ball, Bola de massagem, Heavy Ball, Tatú de espuma, Medicine Ball e etc',
                images: [
                    {
                        url: imgBolas1,
                        legend: 'Bolas'
                    },
                    {
                        url: imgBolas2,
                        legend: 'Bolas'
                    },
                    {
                        url: imgBolas3,
                        legend: 'Bolas'
                    },
                    {
                        url: imgBolas4,
                        legend: 'Bolas'
                    },
                    {
                        url: imgBolas5,
                        legend: 'Bolas'
                    },
                    {
                        url: imgBolas6,
                        legend: 'Bolas'
                    }
                ]
            },
            {
                title: 'Pilates e fitness',
                description: 'Faixas Elásticas, Rolos, Alteres, Tubos Elásticos, Tornozeleiras, Tatames e etc',
                images: [
                    {
                        url: imgPilates1,
                        legend: 'Pilates'
                    },
                    {
                        url: imgPilates2,
                        legend: 'Pilates'
                    },
                    {
                        url: imgPilates3,
                        legend: 'Pilates'
                    },
                    {
                        url: imgPilates4,
                        legend: 'Pilates'
                    },
                    {
                        url: imgPilates5,
                        legend: 'Pilates'
                    },
                    {
                        url: imgPilates6,
                        legend: 'Pilates'
                    },
                    {
                        url: imgPilates7,
                        legend: 'Pilates'
                    }
                ]
            },
            {
                title: 'Mecanoterapia',
                description: 'Balance disk, Balancin, Barras diversas, Escadas, Pedalinhos, Pranchas, Exercitadores, Finger Flex e etc',
                images: [
                    {
                        url: imgMecanoterapia1,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia2,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia3,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia4,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia5,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia6,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia7,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia8,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia9,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia10,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia11,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia12,
                        legend: 'Mecanoterapia'
                    },
                    {
                        url: imgMecanoterapia13,
                        legend: 'Mecanoterapia'
                    },
                ]
            },
            {
                title: 'Mesas, Divãs, Escadas, Tablados e etc',
                description: null,
                images: [
                    {
                        url: imgMesas1,
                        legend: 'Mesas'
                    },
                    {
                        url: imgMesas2,
                        legend: 'Mesas'
                    },
                    {
                        url: imgMesas3,
                        legend: 'Mesas'
                    },
                    {
                        url: imgMesas4,
                        legend: 'Mesas'
                    },
                    {
                        url: imgMesas5,
                        legend: 'Mesas'
                    },
                    {
                        url: imgMesas6,
                        legend: 'Mesas'
                    },
                    {
                        url: imgMesas7,
                        legend: 'Mesas'
                    },
                    {
                        url: imgMesas8,
                        legend: 'Mesas'
                    }
                ]
            }
        ]
    }
}

export default database;