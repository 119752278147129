import './App.css';
import Header from "./components/header";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from "./pages/home";
import Footer from "./components/footer";
import Contact from "./pages/contact";
import QuemSomos from "./pages/quem_somos";
import Servicos from "./pages/servicos";

import Produtos from "./pages/products";

function App() {
  return (
    <Router>
      <Header/>
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/quem-somos" element={<QuemSomos />} exact />
          <Route path="/produtos" element={<Produtos.Home />} exact />
          <Route path="/produtos/proteses" element={<Produtos.Proteses.Home />} exact />
          <Route path="/produtos/proteses/mama" element={<Produtos.Proteses.Mama />} exact />
          <Route path="/produtos/proteses/quadril" element={<Produtos.Proteses.Quadril />} exact />
          <Route path="/produtos/proteses/joelho" element={<Produtos.Proteses.Joelho />} exact />
          <Route path="/produtos/proteses/ombro" element={<Produtos.Proteses.Ombro />} exact />
          <Route path="/produtos/proteses/cotovelo" element={<Produtos.Proteses.Cotovelo />} exact />
          <Route path="/produtos/proteses/punho" element={<Produtos.Proteses.Punho />} exact />
          <Route path="/produtos/proteses/maos-e-dedos" element={<Produtos.Proteses.MaosEDedos />} exact />
          <Route path="/produtos/proteses/braco" element={<Produtos.Proteses.Braco />} exact />
          <Route path="/produtos/proteses/antebraco" element={<Produtos.Proteses.Antebraco />} exact />
          <Route path="/produtos/proteses/acima-joelho" element={<Produtos.Proteses.AcimaDoJoelho />} exact />
          <Route path="/produtos/proteses/abaixo-joelho" element={<Produtos.Proteses.AbaixoDoJoelho />} exact />
          <Route path="/produtos/orteses" element={<Produtos.Orteses.Home/>} exact />
          <Route path="/produtos/orteses/ombro" element={<Produtos.Orteses.Ombro/>} exact />
          <Route path="/produtos/orteses/quadril" element={<Produtos.Orteses.Quadril/>} exact />
          <Route path="/produtos/orteses/pes" element={<Produtos.Orteses.Pes/>} exact />
          <Route path="/produtos/orteses/membros-superiores" element={<Produtos.Orteses.MembrosSuperiores/>} exact />
          <Route path="/produtos/orteses/membros-inferiores" element={<Produtos.Orteses.MembrosInferiores/>} exact />
          <Route path="/produtos/orteses/tronco" element={<Produtos.Orteses.Tronco/>} exact />
          <Route path="/produtos/orteses/diabeticos" element={<Produtos.Orteses.Diabeticos/>} exact />
          <Route path="/produtos/medico-hospitalar" element={<Produtos.MedicoHospitalar.Home/>} exact />
          <Route path="/produtos/medico-hospitalar/cadeiras" element={<Produtos.MedicoHospitalar.CadeirasRodas/>} exact />
          <Route path="/produtos/medico-hospitalar/home-care" element={<Produtos.MedicoHospitalar.HomeCare/>} exact />
          <Route path="/produtos/medico-hospitalar/infralux" element={<Produtos.MedicoHospitalar.Infralux/>} exact />
          <Route path="/produtos/medico-hospitalar/scooter" element={<Produtos.MedicoHospitalar.Scooter/>} exact />
          <Route path="/produtos/medico-hospitalar/andadores" element={<Produtos.MedicoHospitalar.Andadores/>} exact />
          <Route path="/produtos/fisioterapia" element={<Produtos.Fisioterapia.Home/>} exact />
          <Route path="/servicos" element={<Servicos />} exact />
          <Route path="/contato" element={<Contact />} exact />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
