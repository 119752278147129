import React from "react";
import imgAssento1 from "../../../assets/assentos_1.jpg";
import imgAssento2 from "../../../assets/assentos_2.jpg";
import imgAssento3 from "../../../assets/assentos_3.jpg";
import imgAssento4 from "../../../assets/assentos_4.jpg";
import imgGuincho from "../../../assets/guincho.jpg";

function HomeCare() {
    return (
        <>
            <h2>Home-care</h2>
            <h3>Assentos sanitários especiais</h3>
            <ul className="images">
                <li className="fisioterapia_images_item">
                    <img className="image" src={imgAssento1} alt="Assento" />
                </li>
                <li className="fisioterapia_images_item">
                    <img className="image" src={imgAssento2} alt="Assento" />
                </li>
                <li className="fisioterapia_images_item">
                    <img className="image" src={imgAssento3} alt="Assento" />
                </li>
                <li className="fisioterapia_images_item">
                    <img className="image" src={imgAssento4} alt="Assento" />
                </li>
            </ul>
            <h3>Guincho elétrico</h3>
            <p>Equipamento desenvolvido para efetuar a passagem da pessoa portadora de deficiência física da sua cadeira de rodas, para cama, vasos sanitário, banho, etc…, possui estrutura em aço carbono tubular com rodízios e desmontável, facilitando a movimentação com segurança do usuário e o seu transporte, o sistema de levante é acionado por um atuador linear com motor elétrico com capacidade de até 250 kg, alimentado por duas baterias de 12v 7amp que facilitam o seu uso, recarregáveis através de um carregador inteligente multivoltagem 90/240v com saída de 24v 1,5amp que efetua todas as curvas corretas de carga maximizando o aproveitamento energético das baterias, com consumo ínfimo de energia elétrica.</p>
            <ul className="images">
                <li className="fisioterapia_images_item">
                    <img className="image" src={imgGuincho} alt="Guincho" />
                </li>
            </ul>
        </>
    )
}

export default HomeCare;