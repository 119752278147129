import React from "react";
import imgPes1 from "../../../assets/produtos_orteses_pes_1.jpg"
import imgPes2 from "../../../assets/produtos_orteses_pes_2.jpg"
import imgPes3 from "../../../assets/produtos_orteses_pes_3.jpg"
import imgPes4 from "../../../assets/produtos_orteses_pes_4.jpg"
import imgPes5 from "../../../assets/produtos_orteses_pes_5.jpg"
import imgPes6 from "../../../assets/produtos_orteses_pes_6.jpg"
import pdfPalmilha from "../../../assets/produtos_orteses_pes_palmilha.pdf"


function Pes() {
    return (
        <>
            <h2>Pés</h2>
            <h3>Molded Ancle Foot Ortosis (MAFO)</h3>
            <p>
                MAFO Articulado (com limitação de flexão plantar em 90°);<br />
                Mantém a Flexão plantar limitada a 90° (evita o padrão equino);<br />
                Permite a Flexão dorsal (não atrapalha na deambulação); Contém a Inversão e Eversão;<br />
                É utilizado com Tênis (não necessita de calçados especiais);<br />
                Confeccionado sob medida;
            </p>
            <p>
                Material utilizado:<br />
                Polipropileno e softform Fixação com velcro
            </p>
            <div className="images">
                <img className="image" src={imgPes1} alt="MAFO"/>
                <img className="image" src={imgPes2} alt="MAFO"/>
                <img className="image" src={imgPes3} alt="MAFO" />
            </div>
            <h3>Palmilha PEGASSIST</h3>
            <p>
                A palmilha PEGASSIST PQ de alívio de pressão imediata é um produto inovador
                desenvolvido para auxiliar na cicatrização de lesões plantares, úlceras plantares
                e pontos de pressão. Feita com uma combinação de Plastazote® e base Multi-Foam de
                15 mm com cobertura Poron® de 3 mm, essa palmilha oferece amortecimento que elimina
                a incidência de edema de anel e abrasão na borda. Além disso, a placa estabilizadora
                incluída ajuda a manter a integridade do produto e os pinos removíveis permitem o
                alívio de pressão imediata das feridas e ulcerações dos pés.
                <br/><br />
                <a href={pdfPalmilha} download>Baixe aqui as instruções de uso</a>
            </p>
            <div className="images">
                <img className="image" src={imgPes4} alt="PesAssist"/>
                <img className="image" src={imgPes5} alt="PegAssist"/>
                <img className="image" src={imgPes6} alt="PegAssist"/>
            </div>
        </>
    )
}

export default Pes;