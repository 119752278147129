import React from "react";

function Infralux() {
    return (
        <>
            <h2>Infralux</h2>
            <h3>Especificações</h3>
            <ul>
                <li>Opcional: Lâmpada de 150 ou 250 watts</li>
                <li>Soquete em Porcelana E-27</li>
                <li>Base em Tubo 5/8</li>
                <li>Pintura eletrostática</li>
            </ul>
        </>
    )
}

export default Infralux;